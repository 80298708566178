import React from "react";

import styles from "./SocialFooter.module.scss";
import InstagramSrc from "../assets/images/icons/icon-insta.svg";
import FacebookSrc from "../assets/images/icons/icon-facebook.svg";
import YoutubeSrc from "../assets/images/icons/icon-yt.svg";
import { SocialIcon } from "./SocialIcon";
import { useAppNavigate } from "../hooks/useAppNavigate";
import { privacyRoute } from "../pages/RoutePath";

type Props = {
    className?: string;
}

export function SocialFooter(props: Props) {
    const navigate = useAppNavigate();
    
    return (
        <div className={`${styles.wrapper} ${props.className ?? ""}`}>
            <a className={styles.website} href="https://www.pagani.com/">pagani.com</a>
            <p className={styles.link} onClick={() => navigate(privacyRoute, {replace: false})}>Privacy Policy</p>

            <div className={styles.socialWrapper}>
                <SocialIcon
                    className={styles.icon}
                    href="https://www.instagram.com/paganiautomobili"
                    src={InstagramSrc}
                />
                <SocialIcon
                    className={styles.icon}
                    href="https://www.facebook.com/PaganiAutomobili/"
                    src={FacebookSrc}
                />
                <SocialIcon
                    className={styles.icon}
                    href="https://www.youtube.com/channel/UCO75zkg9l4U9cPdHVbj5DDw"
                    src={YoutubeSrc}
                />
            </div>
        </div>
    );
}
