import React, { useEffect, useState } from 'react'
import { Header } from '../components/Header'
import { useAtomValue, useSetAtom } from 'jotai';
import { useStrings } from '../localization/hooks/useStrings';
import { useAppNavigate } from '../hooks/useAppNavigate';
import { localeAtom } from '../localization/atoms/localeAtom';

export default function ErrorPage() {
    const strings = useStrings();
    const navigate = useAppNavigate();
    const locale = useAtomValue(localeAtom) ?? "it";

    return (
        <>
            <Header hasMenuButton={true} />
            <div className="page top-p-header small-horizontal-padding">
                <div className="page-box error">
                    <h1>{strings.common$error_page_title}</h1>
                    <h3>{strings.common$error_page_subtitle}</h3>
                </div>
            </div>
        </>
    )
}
