export default {
    "it": {
        "common$select_language": "Scegli la lingua",
        "common$playing": "In riproduzione",
        "common$play": "Riproduci",
        "common$panel": "Panel {0}",
        "common$read_all": "LEGGI TUTTO",
        "page1$title": "Benvenuti",
        "page1$subtitle": "Sottotilo",
        "page1$text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
        "page1$panel": "{0} e {1}",
        "page1_item0$title": "Benvenuti",
        "page1_item0$audio": "_it.mp3",
        "page1_item0$name": "T0",
        "page1_item1$title": "Riconoscimenti",
        "page1_item1$audio": "_it.mp3",
        "page1_item1$name": "T20",
        "page2$title": "Storia",
        "page2$subtitle": "Sottotilo",
        "page2$text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
        "page2$panel": "Da {0} a {1}",
        "page2_item0$title": "Il primo Atelier",
        "page2_item0$audio": "_it.mp3",
        "page2_item0$name": "T3",
        "page2_item1$title": "La minimoto di Horacio",
        "page2_item1$audio": "_it.mp3",
        "page2_item1$name": "T4",
        "page2_item2$title": "La prima Pagani",
        "page2_item2$audio": "_it.mp3",
        "page2_item2$name": "T6",
        "page2_item3$title": "Le origini",
        "page2_item3$audio": "_it.mp3",
        "page2_item3$name": "T9",
        "page2_item4$title": "Dare forma ai sogni",
        "page2_item4$audio": "_it.mp3",
        "page2_item4$name": "T11",
        "page2_item5$title": "Oreste Berta e Juan Manuel Fangio",
        "page2_item5$audio": "_it.mp3",
        "page2_item5$name": "T14",
        "page2_item6$title": "L’esordio in Lamborghini",
        "page2_item6$audio": "_it.mp3",
        "page2_item6$name": "T15",
        "page2_item7$title": "La Countach",
        "page2_item7$audio": "_it.mp3",
        "page2_item7$name": "T16",
        "page2_item8$title": "Nasce la Modena Design",
        "page2_item8$audio": "_it.mp3",
        "page2_item8$name": "T26",
        "page2_item9$title": "Le collaborazioni",
        "page2_item9$audio": "_it.mp3",
        "page2_item9$name": "T27",
        "page2_item10$title": "La Fangio F1",
        "page2_item10$audio": "_it.mp3",
        "page2_item10$name": "T28",
        "page2_item11$title": "Il progetto Zonda C12",
        "page2_item11$audio": "_it.mp3",
        "page2_item11$name": "T29",
        "page2_item13$title": "Huayra",
        "page2_item13$audio": "_it.mp3",
        "page2_item13$name": "T39",
        "page2_item12$title": "Galleria disegni e bozzetti",
        "page2_item12$audio": "_it.mp3",
        "page2_item12$name": "T41",
        "page3$title": "Vetture",
        "page3$subtitle": "Sottotilo",
        "page3$text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
        "page3$panel": "{0} Vetture",
        "page3_item0$title": "Zonda La Nonna",
        "page3_item0$audio": "_it.mp3",
        "page3_item0$name": "T12",
        "page3_item0$description": "La “Nonna” è una versione molto speciale della Zonda, veste il telaio numero 002 ed è stata fabbricata nell’anno 1997. La carrozzeria originale è stata continuamente modificata durante gli ultimi 20 anni, per testare nuove soluzioni aerodinamiche e stilistiche, differenti parti meccaniche e componenti destinati a modelli successivi. \nAll’epoca della sua costruzione, le risorse non erano sufficienti per poter disporre di una vettura per ogni scopo, quindi la Nonna fu di volta in volta l’auto di prova per i clienti, il muletto per i test, la demo per i giornalisti e così via.\n“La Nonna” è stata il vero banco prova per tutto lo sviluppo dei progetti Pagani e nella sua lunga vita ha percorso l’incredibile totale di oltre 500.000 chilometri.\n",
        "page3_item1$title": "Zonda S",
        "page3_item1$audio": "_it.mp3",
        "page3_item1$name": "T17",
        "page3_item1$description": "La Zonda numero 20, una C12S di colore blu, è stata costruita nel 2003 ed è stata protagonista del film “Adrenalina blu - La leggenda di Michel Vaillant”. \nIl caratteristico colre Blue Francia della carrozzeria viene ripreso per la prima e unica volta anche sulle razze dei cerchioni, colorate in tinta. Questa singolare vettura non è mai stata messa in vendita, fino a quando un principe arabo, particolarmente colpito dalla sua bellezza, ha insistito per acquistarla. Una targa con la dedica al proprietario è ancora visibile al suo interno, proprio dietro ai sedili. \nLa C12S beneficiò di una versione elaborata dalla AMG del motore M120, la cui cilindrata venne aumentata a 7 litri. La potenza saliva a 550 CV e il peso scendeva di 10 kg grazie ad alcuni accorgimenti, come l'adozione di un nuovo scarico più leggero. Esteticamente la C12 S si distingue per l’alettone posteriore sdoppiato e per il frontale che presenta un chiaro richiamo al mondo della Formula 1. A partire dal 2003, la C12 S venne costruita anche in versione Roadster.",
        "page3_item2$title": "Zonda F",
        "page3_item2$audio": "_it.mp3",
        "page3_item2$name": "T24",
        "page3_item2$description": "Nel 2006, a Ginevra, venne presentata la Roadster F, differente dal coupé solo per il tetto removibile, del peso di soli 5 kg. \nAnche la Roadster F veniva proposta in versione standard da 602 cavalli e Clubsport da 650 cavalli.\nL’idea era quella di costruire una roadster leggera quanto il coupé, ma raggiungere questo ambizioso risultato richiese un lavoro enorme. \nLa Pagani Zonda è stata la prima supercar su cui era disponibile anche la guida a destra: le supercar classiche, come ad esempio la Ferrari F40, la Enzo, la Carrera GT e la Bugatti Veyron, avevano tutte il volante a sinistra. \nQuesto importante aspetto ha permesso alla Zonda di sfondare sul mercato inglese e su quello di Hong Kong, divenuto rapidamente uno dei più importanti per Pagani. \nAncora oggi la Zonda è l’unica supercar completamente convertibile e ciò significa che il cliente può comprare la versione con guida a destra e convertirla per la guida a sinistra.",
        "page3_item3$title": "Zonda Cinque Roadster",
        "page3_item3$audio": "_it.mp3",
        "page3_item3$name": "T31",
        "page3_item3$description": "La Zonda Cinque è probabilmente la macchina più famosa e ricercata tra tutte le Pagani, il nostro unicorno.\nL’auto fu costruita in edizione speciale su richiesta del dealer Pagani di Hong Kong, in soli 5 esemplari coupé e 5 Roadster.  L’esemplare esposto è la 5 di 5.\nIl suo prezzo originale era di 1,3 milioni di Euro, ma attualmente la Zonda Cinque è la Pagani più costosa al mondo, valutata attorno agli 8 milioni di euro.\nLa Cinque rappresenta un notevole passo in avanti nella tecnologia di Pagani Automobili, in quanto è la prima vettura a montare il cambio robotizzato con palette al volante.\nMa la sua caratteristica di eccellenza è la vasca centrale in carbotitanio, un materiale esclusivo sviluppato da Pagani, utilizzato anche su Zonda R e Huayra.",
        "page3_item4$title": "Zonda Barchetta Revo",
        "page3_item4$audio": "_it.mp3",
        "page3_item4$name": "T36",
        "page3_item4$description": "La Pagani Zonda Revo Barchetta si può definire un sogno su quattro ruote. Unico esemplare di proprietà di Horacio Pagani, è un modello a cui il designer è molto legato. I colori della livrea si ispirano infatti a uno dei modellini in alluminio esposti al Museo, creati da un giovane Horacio quando, a 12 anni durante l’adolescenza argentina, si dilettava già a costruire piccole auto da corsa.\nLa Zonda Revo Barchetta costituisce un progetto ambizioso: unire due delle vetture più iconiche del brand. Da un lato la bellezza delle linee di Zonda HP Barchetta stradale, dall’altro l’anima ribelle e il concentrato di prestazioni di Zonda Revolución. E l’obiettivo è uno solo: emozionare e far sognare chi la vede al primo sguardo.\nSenza dubbio si tratta di un progetto che ha richiesto al team Pagani uno sforzo non indifferente, in particolare per sviluppare una struttura centrale dedicata solo per questo modello applicando i più avanzati sistemi in materiali compositi avanzati.\nAll’animo racing contribuiscono il motore V12 aspirato Mercedes-AMG che eroga fino a 800 cavalli e 750 Nm coppia e il sound emesso dallo scarico in titanio rivestito in ceramica che, con l’aumentare dei giri, è un boato di emozioni. Cone nel più puro stile Pagani, anche il più piccolo dettaglio è curato maniacalmente: è il caso delle componenti in alluminio anodizzato blu ricavate dal pieno.\nIn sintesi, si può affermare che questa vettura è un vero omaggio alla passione per l’automobile: a partire dalla monoscocca in carbon-titanio al cambio trasversale sequenziale a 6 marce con infusione in magnesio ai freni carbo cercamici autoventilanti, Zonda Revo Barchetta è un concentrato di innovazione e tecnologia racchiuso in uno scrigno di pura bellezza ed eleganza.",
        "page3_item5$title": "Pagani Imola",
        "page3_item5$audio": "_it.mp3",
        "page3_item5$name": "T36",
        "page3_item5$description": "La Pagani Imola è un omaggio al circuito della Motor Valley, prende infatti il nome dalla pista su cui è stata messa a punto, dove ha percorso oltre 16.000 km a velocità di gara, l’equivalente di circa tre volte la 24 ore di Le Mans.\nPagani Imola rappresenta una tappa fondamentale nel progresso tecnologico e scientifico di Pagani Automobili. E’ infatti è una vettura-laboratorio dove sono state testate e sviluppate innovazioni importanti applicate in futuro anche ad altri modelli. Ne sono un esempio il nuovo telaio in carbo-titanio, il sistema di ammortizzatori attivi a gestione elettronica sulla singola ruota interconnessi tra loro e il sistema “smart gas” che riduce il tempo di cambiata esaltando il carattere racing dell’auto.\nProdotta in soli cinque esemplari, è equipaggiata con il motore V12 da 827 cavalli e ha un peso di 1246 kg. Alla sua leggerezza contribuisce anche un sistema di verniciatura, l’Acquarello Light, che riduce il peso di ben 5 kg mantenendo inalterata l’intensità del colore. Il suo valore oggi è di circa 5 milioni di euro.",
        "page3_item6$title": "Huayra Coupé",
        "page3_item6$audio": "_it.mp3",
        "page3_item6$name": "T39",
        "page3_item6$description": "La lunga evoluzione stilistica che ha portato alla nascita del Coupé Huayra, dalla prima versione datata 2003, al progetto finale del 2010, è testimoniata dai numerosi modelli in resina su cui sono stati ipotizzati differenti volumi di carrozzeria, prese d’aria alternative e frontalini più o meno aggressivi. Oltre 7 anni di sviluppo, durante i quali è stato possibile sintetizzare una forma elegante e sinuosa, in cui ogni singola linea di carrozzeria ha un inizio ed una fine precisi. Un lavoro meticoloso, per ottenere un design senza tempo, un concetto stilistico tutto nuovo rispetto a Zonda, ma sempre unico e riconoscibile, come solo una Pagani sa essere."
    },
    "en": {
        "common$select_language": "Select Language",
        "common$playing": "Playing",
        "common$play": "Play",
        "common$panel": "Panel {0}",
        "common$read_all": "READ MORE",
        "page1$title": "Welcome",
        "page1$subtitle": "Subtitle",
        "page1$text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
        "page1$panel": "{0} and {1}",
        "page1_item0$title": "Welcome",
        "page1_item0$audio": "_en.mp3",
        "page1_item0$name": "T0",
        "page1_item1$title": "Credits",
        "page1_item1$audio": "_en.mp3",
        "page1_item1$name": "T20",
        "page2$title": "History",
        "page2$subtitle": "Subtitle",
        "page2$text": "",
        "page2$panel": "From {0} to {1}",
        "page2_item0$title": "The first studio",
        "page2_item0$audio": "_en.mp3",
        "page2_item0$name": "T3",
        "page2_item1$title": "Horacio's mini motorbike",
        "page2_item1$audio": "_en.mp3",
        "page2_item1$name": "T4",
        "page2_item2$title": "The first Pagani",
        "page2_item2$audio": "_en.mp3",
        "page2_item2$name": "T6",
        "page2_item3$title": "The beginnings",
        "page2_item3$audio": "_en.mp3",
        "page2_item3$name": "T9",
        "page2_item4$title": "Making derams come true",
        "page2_item4$audio": "_en.mp3",
        "page2_item4$name": "T11",
        "page2_item5$title": "Oreste Berta and Juan Manuel Fangio",
        "page2_item5$audio": "_en.mp3",
        "page2_item5$name": "T14",
        "page2_item6$title": "Beginnings at Lamborghini: jeep LM and Countach Evoluzione",
        "page2_item6$audio": "_en.mp3",
        "page2_item6$name": "T15",
        "page2_item7$title": "Countach Anniversary",
        "page2_item7$audio": "_en.mp3",
        "page2_item7$name": "T16",
        "page2_item8$title": "Modena Design is born",
        "page2_item8$audio": "_en.mp3",
        "page2_item8$name": "T26",
        "page2_item9$title": "90 years: collaborations with Dallara, Aprilia, Ferrari, Daihatsu and Renault",
        "page2_item9$audio": "_en.mp3",
        "page2_item9$name": "T27",
        "page2_item10$title": "The Fangio F1",
        "page2_item10$audio": "_en.mp3",
        "page2_item10$name": "T28",
        "page2_item11$title": "The Zonda C12 Project",
        "page2_item11$audio": "_en.mp3",
        "page2_item11$name": "T29",
        "page2_item13$title": "Huayra: style evolution",
        "page2_item13$audio": "_en.mp3",
        "page2_item13$name": "T39",
        "page2_item12$title": "Gallery of drawings and sketches",
        "page2_item12$audio": "_en.mp3",
        "page2_item12$name": "T41",
        "page3$title": "Cars",
        "page3$subtitle": "Subtitle",
        "page3$text": "",
        "page3$panel": "{0} Cars",
        "page3_item0$title": "Zonda La Nonna",
        "page3_item0$audio": "_en.mp3",
        "page3_item0$name": "T12",
        "page3_item0$description": "“La Nonna”, which means “The Grandma”, is a very special version of the Zonda, with the chassis number 002. The car was manufactured in the year 1997. The original bodywork has been continuously modified over the last 20 years, to test new aerodynamic and stylistic solutions, different mechanical parts and components intended for later models. \nAt the time of its construction, there were insufficient resources to have a car for every purpose, so the Nonna was the test car for the customers each time, the test forklift, the demo for journalists and so on.\n“La Nonna” was the real bench test for the development of all the Pagani projects and in its long life it has travelled an incredible total of over 500,000 kilometers.",
        "page3_item1$title": "Zonda S",
        "page3_item1$audio": "_en.mp3",
        "page3_item1$name": "T17",
        "page3_item1$description": "Built in 2003, the Zonda number 20  (a blue C12 S) starred in the movie \"Michel Vaillant\". \nFor the first and only time ever, the distinctive French Blue shade of the bodywork is also featured on the coordinating rim spokes on this model. This unique car was never put up for sale, however an Arab prince - particularly impressed by its stunning appearance - insisted on buying it.  A plaque bearing a dedication to the owner can still be seen inside, mounted on the rear of the seats. \nThe C12 S was enhanced by an AMG-developed  version of the M120 engine with an increased  displacement of 7 litres.  Thanks to a series of tweaks, such as the adoption of a new, lighter exhaust, the model offers a higher output  of 550 hp while weighing in at 10 kg less.  Aesthetically speaking, the C12 S stands out for its split rear spoiler and its front end, which pays clear tribute to the world of Formula 1. As of 2003, the C12 S has also been also built in the Roadster version.\n",
        "page3_item2$title": "Zonda F",
        "page3_item2$audio": "_en.mp3",
        "page3_item2$name": "T24",
        "page3_item2$description": "The Roadster F was presented in 2006, in Geneva, and the only difference between it and the coupé was the removable roof, weighing only 5 kg. \nThe Roadster F was proposed in a standard version with 602 hp and a Clubsport version with 650 hp.\nThe idea was to build a roadster that was as light as the coupé, but achieving this ambitious result required an enormous amount of work. \nThe Pagani Zonda was the first supercar that also came in a right-hand drive version: the classic supercars, such as the Ferrari F40, the Enzo, the Carrera GT and the Bugatti Veyron, all had the steering wheel on the left. \nThis important aspect made it possible for the Zonda to break into the English and Hong Kong market, which quickly becoming one of the most important markets for Pagani. \nEven today the Zonda is the only fully convertible supercar and this means that the customer can buy the right-hand drive version and convert it into a left-hand drive.",
        "page3_item3$title": "Zonda Cinque Roadster",
        "page3_item3$audio": "_en.mp3",
        "page3_item3$name": "T31",
        "page3_item3$description": "The Zonda Cinque is probably the most famous and sought-after of all the Pagani cars, our unicorn.\nThe car was built in a special edition at the request of the Pagani dealer in Hong Kong, in only 5 coupé and 5 Roadsters. The example on display is number 5 of 5.\nIts original price was 1.3 million euros, but currently the Zonda 5 is the most expensive Pagani in the world, valued at around 8 million euros.\nThe Cinque represents a significant step forward in Pagani Automobili’s technology, as it is the first car to mount the robotized paddle-shift gearbox on the steering wheel.\nBut its most distinguished characteristic is the central tank in carbotanium, an exclusive material developed by Pagani, also used on the Zonda R and Huayra.",
        "page3_item4$title": "Zonda Barchetta Revo",
        "page3_item4$audio": "_en.mp3",
        "page3_item4$name": "T36",
        "page3_item4$description": "You can think of the Pagani Zonda Revo Barchetta as a dream on four wheels. The only model owned by Horacio Pagani, it's one he's very attached to. The livery colours are inspired by one of the aluminium models exhibited at the Museum, created by a young Horacio when, at the age of 12, during his adolescence in Argentina, he was already dabbling in building small race cars.\nThe Zonda Revo Barchetta is the result of an ambitious project: to combine two of the brand's most iconic cars. On the one hand, the beauty of the Zonda HP Barchetta stradale, on the other, the rebellious spirit and high-performance of the Zonda Revolución. And there's just one goal: to get people excited and make their dreams come true the first time they see it.\nUndoubtedly, this project required a considerable effort from the Pagani team, particularly when it came to developing a central frame for this model, based on the most advanced composite materials.\nThe Mercedes-AMG aspirated V12 engine delivers up to 800 horsepower and 750 Nm of torque and the sound that comes out of the ceramic-coated titanium exhaust adds to the racing spirit, which roars out amazing feelings. As in every Pagani project, even the smallest detail is obsessively curated: this is the case with the blue anodised aluminium components that have been machined from solid billets.\nIn short, we can say this is a real tribute to the passion for cars: from the carbo-titanium monocoque chassis to the 6-speed magnesium-infused sequential transverse gearbox and the internally-ventilated carbon-ceramic brakes, Zonda Revo Barchetta is a blend of innovation and technology wrapped around a trove of pure beauty and elegance",
        "page3_item5$title": "Pagani Imola",
        "page3_item5$audio": "_en.mp3",
        "page3_item5$name": "T36",
        "page3_item5$description": "The Pagani Imola is a tribute to the Motor Valley circuit. In fact it takes its name from the track where it was developed, where it travelled over 16,000 km at race speed, the equivalent of about three times the 24 Hours of Le Mans.\nThe Pagani Imola represents a milestone in Pagani Automobili's technological and scientific progress. It's basically a testbed, where important innovations have been tested and developed and later applied to other models. An example is the new carbo-titanium chassis, the electronically-controlled active shock absorber system located on each wheel interlinked with the \"smart gas\" system, which reduces the changeover time by enhancing the car's racing character.\nProduced in only five pieces, it's equipped with the 827 horsepower V12 engine and weighs 1246 kg. The Acquarello Light painting system also adds to its lightness, reducing the weight by 5 kg while keeping the colour intensity unchanged. Its value today is around 5 million euros.",
        "page3_item6$title": "Huayra Coupé",
        "page3_item6$audio": "_en.mp3",
        "page3_item6$name": "T39",
        "page3_item6$description": "The long stylistic evolution that led to the creation of the Huayra Coupé, from the first version in 2003 to the final design of 2010, can be seen in the numerous resin models that were used to experiment with different chassis volumes, alternative air intakes and more or less aggressive faceplates. There were more than 7 years of development, during which it was possible to synthesize an elegant and sinuous form, in which every single body line has a precise beginning and end. The meticulous work achieved a timeless design, a new stylistic concept with respect to Zonda, but always unique and recognizable, as only a Pagani can be."
    },
    "es": {
        "common$select_language": "",
        "common$playing": "",
        "common$play": "",
        "common$panel": "",
        "common$read_all": "",
        "page1$title": "",
        "page1$subtitle": "",
        "page1$text": "",
        "page1$panel": "",
        "page1_item0$title": "Bienvenida",
        "page1_item0$audio": "",
        "page1_item0$name": "",
        "page1_item1$title": "RECONOCIMIENTOS Y PREMIOS",
        "page1_item1$audio": "",
        "page1_item1$name": "",
        "page2$title": "",
        "page2$subtitle": "",
        "page2$text": "",
        "page2$panel": "",
        "page2_item0$title": "EL PRIMER TALLER",
        "page2_item0$audio": "",
        "page2_item0$name": "",
        "page2_item1$title": "LA MINIMOTO DE HORACIO",
        "page2_item1$audio": "",
        "page2_item1$name": "",
        "page2_item2$title": "EL PRIMER PAGANI",
        "page2_item2$audio": "",
        "page2_item2$name": "",
        "page2_item3$title": "LOS ORÍGENES",
        "page2_item3$audio": "",
        "page2_item3$name": "",
        "page2_item4$title": "DAR FORMA A LOS SUEÑOS",
        "page2_item4$audio": "",
        "page2_item4$name": "",
        "page2_item5$title": "ORESTE BERTA Y JUAN MANUEL FANGIO",
        "page2_item5$audio": "",
        "page2_item5$name": "",
        "page2_item6$title": "LOS COMIENZOS EN LAMBORGHINI: JEEP LM Y COUNTACH EVOLUZIONE",
        "page2_item6$audio": "",
        "page2_item6$name": "",
        "page2_item7$title": "EL COUNTACH ANNIVERSARY",
        "page2_item7$audio": "",
        "page2_item7$name": "",
        "page2_item8$title": "NACE LA MODENA DESIGN",
        "page2_item8$audio": "",
        "page2_item8$name": "",
        "page2_item9$title": "AÑOS 90: LAS COLABORACIONES CON DALLARA, APRILIA, FERRARI, DAIHATSU Y RENAULT",
        "page2_item9$audio": "",
        "page2_item9$name": "",
        "page2_item10$title": "EL FANGIO F1",
        "page2_item10$audio": "",
        "page2_item10$name": "",
        "page2_item11$title": "EL PROYECTO ZONDA C12",
        "page2_item11$audio": "",
        "page2_item11$name": "",
        "page2_item13$title": "HUAYRA: LA EVOLUCIÓN DEL ESTILO",
        "page2_item13$audio": "",
        "page2_item13$name": "",
        "page2_item12$title": "GALERÍA DE DIBUJOS Y BOCETOS",
        "page2_item12$audio": "",
        "page2_item12$name": "",
        "page3$title": "",
        "page3$subtitle": "",
        "page3$text": "",
        "page3$panel": "",
        "page3_item0$title": "Zonda La Nonna",
        "page3_item0$audio": "",
        "page3_item0$name": "",
        "page3_item0$description": "",
        "page3_item1$title": "",
        "page3_item1$audio": "",
        "page3_item1$name": "",
        "page3_item1$description": "",
        "page3_item2$title": "",
        "page3_item2$audio": "",
        "page3_item2$name": "",
        "page3_item2$description": "",
        "page3_item3$title": "",
        "page3_item3$audio": "",
        "page3_item3$name": "",
        "page3_item3$description": "",
        "page3_item4$title": "",
        "page3_item4$audio": "",
        "page3_item4$name": "",
        "page3_item4$description": "",
        "page3_item5$title": "",
        "page3_item5$audio": "",
        "page3_item5$name": "",
        "page3_item5$description": "",
        "page3_item6$title": "",
        "page3_item6$audio": "",
        "page3_item6$name": "",
        "page3_item6$description": ""
    },
    "de": {
        "common$select_language": "",
        "common$playing": "",
        "common$play": "",
        "common$panel": "",
        "common$read_all": "",
        "page1$title": "",
        "page1$subtitle": "",
        "page1$text": "",
        "page1$panel": "",
        "page1_item0$title": "BEGRÜßUNG",
        "page1_item0$audio": "",
        "page1_item0$name": "",
        "page1_item1$title": "AUSZEICHNUNGEN UND AWARDS",
        "page1_item1$audio": "",
        "page1_item1$name": "",
        "page2$title": "",
        "page2$subtitle": "",
        "page2$text": "",
        "page2$panel": "",
        "page2_item0$title": "DIE ERSTE WERKSTATT",
        "page2_item0$audio": "",
        "page2_item0$name": "",
        "page2_item1$title": "PAGANIS MINI-MOTORRAD",
        "page2_item1$audio": "",
        "page2_item1$name": "",
        "page2_item2$title": "DAS ERSTE PAGANI-AUTO",
        "page2_item2$audio": "",
        "page2_item2$name": "",
        "page2_item3$title": "DIE URSPRÜNGE",
        "page2_item3$audio": "",
        "page2_item3$name": "",
        "page2_item4$title": "TRÄUME WERDEN REALITÄT",
        "page2_item4$audio": "",
        "page2_item4$name": "",
        "page2_item5$title": "ORESTE BERTA UND JUAN MANUEL FANGIO",
        "page2_item5$audio": "",
        "page2_item5$name": "",
        "page2_item6$title": "DIE ANFÄNGE BEI LAMBORGHINI: DER JEEP LM UND DER COUNTACH EVOLUZIONE",
        "page2_item6$audio": "",
        "page2_item6$name": "",
        "page2_item7$title": "DER COUNTACH ANNIVERSARY",
        "page2_item7$audio": "",
        "page2_item7$name": "",
        "page2_item8$title": "DER BEGINN VON MODENA DESIGN",
        "page2_item8$audio": "",
        "page2_item8$name": "",
        "page2_item9$title": "DIE 90ER-JAHRE: DIE KOOPERATIONEN MIT DALLARA, APRILIA, FERRARI, DAIHATSU UND RENAULT",
        "page2_item9$audio": "",
        "page2_item9$name": "",
        "page2_item10$title": "DER FANGIO F1",
        "page2_item10$audio": "",
        "page2_item10$name": "",
        "page2_item11$title": "DAS AUTO-PROJEKT ZONDA C12",
        "page2_item11$audio": "",
        "page2_item11$name": "",
        "page2_item13$title": "HUAYRA: DIE STILISTISCHE ENTWICKLUNG",
        "page2_item13$audio": "",
        "page2_item13$name": "",
        "page2_item12$title": "AUSGESTELLTE ZEICHNUNGEN UND SKIZZEN",
        "page2_item12$audio": "",
        "page2_item12$name": "",
        "page3$title": "",
        "page3$subtitle": "",
        "page3$text": "",
        "page3$panel": "",
        "page3_item0$title": "Zonda La Nonna",
        "page3_item0$audio": "",
        "page3_item0$name": "",
        "page3_item0$description": "",
        "page3_item1$title": "",
        "page3_item1$audio": "",
        "page3_item1$name": "",
        "page3_item1$description": "",
        "page3_item2$title": "",
        "page3_item2$audio": "",
        "page3_item2$name": "",
        "page3_item2$description": "",
        "page3_item3$title": "",
        "page3_item3$audio": "",
        "page3_item3$name": "",
        "page3_item3$description": "",
        "page3_item4$title": "",
        "page3_item4$audio": "",
        "page3_item4$name": "",
        "page3_item4$description": "",
        "page3_item5$title": "",
        "page3_item5$audio": "",
        "page3_item5$name": "",
        "page3_item5$description": "",
        "page3_item6$title": "",
        "page3_item6$audio": "",
        "page3_item6$name": "",
        "page3_item6$description": ""
    },
    "fr": {
        "common$select_language": "",
        "common$playing": "",
        "common$play": "",
        "common$panel": "",
        "common$read_all": "",
        "page1$title": "",
        "page1$subtitle": "",
        "page1$text": "",
        "page1$panel": "",
        "page1_item0$title": "BIENVENUE",
        "page1_item0$audio": "",
        "page1_item0$name": "",
        "page1_item1$title": "RECONNAISSANCES ET PRIX",
        "page1_item1$audio": "",
        "page1_item1$name": "",
        "page2$title": "",
        "page2$subtitle": "",
        "page2$text": "",
        "page2$panel": "",
        "page2_item0$title": "LE PREMIER ATELIER",
        "page2_item0$audio": "",
        "page2_item0$name": "",
        "page2_item1$title": "LA MINI-MOTO D'HORACIO",
        "page2_item1$audio": "",
        "page2_item1$name": "",
        "page2_item2$title": "LA PREMIÈRE PAGANI",
        "page2_item2$audio": "",
        "page2_item2$name": "",
        "page2_item3$title": "LES ORIGINES",
        "page2_item3$audio": "",
        "page2_item3$name": "",
        "page2_item4$title": "DONNER FORME À SES RÊVES",
        "page2_item4$audio": "",
        "page2_item4$name": "",
        "page2_item5$title": "ORESTE BERTA ET JUAN MANUEL FANGIO",
        "page2_item5$audio": "",
        "page2_item5$name": "",
        "page2_item6$title": "LES DÉBUTS CHEZ LAMBORGHINI : JEEP LM ET COUNTACH EVOLUZIONE",
        "page2_item6$audio": "",
        "page2_item6$name": "",
        "page2_item7$title": "LA COUNTACH ANNIVERSARY",
        "page2_item7$audio": "",
        "page2_item7$name": "",
        "page2_item8$title": "MODENA DESIGN VOIT LE JOUR",
        "page2_item8$audio": "",
        "page2_item8$name": "",
        "page2_item9$title": "LES ANNÉES 90 : LES COLLABORATIONS AVEC DALLARA, APRILIA, FERRARI, DAIHATSU ET RENAULT",
        "page2_item9$audio": "",
        "page2_item9$name": "",
        "page2_item10$title": "LA FANGIO F1",
        "page2_item10$audio": "",
        "page2_item10$name": "",
        "page2_item11$title": "LE PROJET ZONDA C12",
        "page2_item11$audio": "",
        "page2_item11$name": "",
        "page2_item13$title": "HUAYRA : L'ÉVOLUTION DU STYLE",
        "page2_item13$audio": "",
        "page2_item13$name": "",
        "page2_item12$title": "GALERIE DESSINS ET ESQUISSES",
        "page2_item12$audio": "",
        "page2_item12$name": "",
        "page3$title": "",
        "page3$subtitle": "",
        "page3$text": "",
        "page3$panel": "",
        "page3_item0$title": "Zonda La Nonna",
        "page3_item0$audio": "",
        "page3_item0$name": "",
        "page3_item0$description": "",
        "page3_item1$title": "",
        "page3_item1$audio": "",
        "page3_item1$name": "",
        "page3_item1$description": "",
        "page3_item2$title": "",
        "page3_item2$audio": "",
        "page3_item2$name": "",
        "page3_item2$description": "",
        "page3_item3$title": "",
        "page3_item3$audio": "",
        "page3_item3$name": "",
        "page3_item3$description": "",
        "page3_item4$title": "",
        "page3_item4$audio": "",
        "page3_item4$name": "",
        "page3_item4$description": "",
        "page3_item5$title": "",
        "page3_item5$audio": "",
        "page3_item5$name": "",
        "page3_item5$description": "",
        "page3_item6$title": "",
        "page3_item6$audio": "",
        "page3_item6$name": "",
        "page3_item6$description": ""
    },
    "jp": {
        "common$select_language": "",
        "common$playing": "",
        "common$play": "",
        "common$panel": "",
        "common$read_all": "",
        "page1$title": "",
        "page1$subtitle": "",
        "page1$text": "",
        "page1$panel": "",
        "page1_item0$title": "ようこそ",
        "page1_item0$audio": "",
        "page1_item0$name": "",
        "page1_item1$title": "数々の賞を受賞",
        "page1_item1$audio": "",
        "page1_item1$name": "",
        "page2$title": "",
        "page2$subtitle": "",
        "page2$text": "",
        "page2$panel": "",
        "page2_item0$title": "最初の工房「タイエル」",
        "page2_item0$audio": "",
        "page2_item0$name": "",
        "page2_item1$title": "オラシオのミニオートバイ",
        "page2_item1$audio": "",
        "page2_item1$name": "",
        "page2_item2$title": "パガーニ第1号",
        "page2_item2$audio": "",
        "page2_item2$name": "",
        "page2_item3$title": "ストーリーの始まり",
        "page2_item3$audio": "",
        "page2_item3$name": "",
        "page2_item4$title": "自分の夢を実現する",
        "page2_item4$audio": "",
        "page2_item4$name": "",
        "page2_item5$title": "オレステ・ベルタとファン・マヌエル・ファンジオ",
        "page2_item5$audio": "",
        "page2_item5$name": "",
        "page2_item6$title": "JEEP LM とカウンタック・エヴォルツィオーネによる、ランボルギーニでのデビュー",
        "page2_item6$audio": "",
        "page2_item6$name": "",
        "page2_item7$title": "カウンタック・アニバーサリー",
        "page2_item7$audio": "",
        "page2_item7$name": "",
        "page2_item8$title": "モデナ・デザインの誕生",
        "page2_item8$audio": "",
        "page2_item8$name": "",
        "page2_item9$title": "ダラーラ、アプリリア、フェラーリ、ダイハツ、ルノーとのコラボレーション",
        "page2_item9$audio": "",
        "page2_item9$name": "",
        "page2_item10$title": "ファンジオ F1",
        "page2_item10$audio": "",
        "page2_item10$name": "",
        "page2_item11$title": "ゾンダ C12 のプロジェクト",
        "page2_item11$audio": "",
        "page2_item11$name": "",
        "page2_item13$title": "スタイルの変遷を語るウアイラ",
        "page2_item13$audio": "",
        "page2_item13$name": "",
        "page2_item12$title": "デッサンとスケッチのギャラリー",
        "page2_item12$audio": "",
        "page2_item12$name": "",
        "page3$title": "",
        "page3$subtitle": "",
        "page3$text": "",
        "page3$panel": "",
        "page3_item0$title": "車台番号2のゾンダ ラ・ノンナ",
        "page3_item0$audio": "",
        "page3_item0$name": "",
        "page3_item0$description": "",
        "page3_item1$title": "",
        "page3_item1$audio": "",
        "page3_item1$name": "",
        "page3_item1$description": "",
        "page3_item2$title": "",
        "page3_item2$audio": "",
        "page3_item2$name": "",
        "page3_item2$description": "",
        "page3_item3$title": "",
        "page3_item3$audio": "",
        "page3_item3$name": "",
        "page3_item3$description": "",
        "page3_item4$title": "",
        "page3_item4$audio": "",
        "page3_item4$name": "",
        "page3_item4$description": "",
        "page3_item5$title": "",
        "page3_item5$audio": "",
        "page3_item5$name": "",
        "page3_item5$description": "",
        "page3_item6$title": "",
        "page3_item6$audio": "",
        "page3_item6$name": "",
        "page3_item6$description": ""
    }
};