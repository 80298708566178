import StringsJson from "./LocalizedStrings";
import OriginalLocalization from "./OriginalLocalization";
import StoryLocalization from "./StoryLocalization";    
import TimelineStrings from "./TimelineStrings";

const combinedStringsIT = {...StringsJson.it, ...OriginalLocalization.it, ...StoryLocalization.it, ...TimelineStrings.it};
const combinedStringsEN = {...StringsJson.en, ...OriginalLocalization.en, ...StoryLocalization.en, ...TimelineStrings.en};
const combinedStrings = {it: combinedStringsIT, en: combinedStringsEN};


export { StringsJson, OriginalLocalization, combinedStrings };

export declare type Locale = keyof typeof combinedStrings;

export const defaultLocale: Locale = "it";

export type LocalizationKey = keyof typeof combinedStrings["it"];

const browserLanguage = navigator.language || ((navigator as any).userLanguage as string);

export const interfaceLocale = browserLanguage?.substring(0, 2) as Locale;

export declare type LocalizedValue<T> = Record<Locale, T>;
