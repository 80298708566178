import React, { useEffect, useState } from "react";
import styles from "./TimelinePage.module.scss";
import { Header } from "../components/Header";
import { SocialFooter } from "../components/SocialFooter";
import { useStrings } from "../localization/hooks/useStrings";
import CoverSrc from "../assets/images/layout/pagani_vetture.png";
import { TextItem } from "../components/TextItem ";
import { page3Entries } from "../models/TextModel";
import { useAtomValue } from "jotai";
import { localeAtom } from "../localization/atoms/localeAtom";
import $ from "jquery";
import { If } from "../mz/types/jsx-control-statements";
import ItalySrc from "../assets/images/layout/italy-map.png";
import ArgentinaSrc from "../assets/images/layout/argentina-map.png";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper";
import { Interweave } from "interweave";
import TimelinePoint from "../assets/images/layout/timeline-point.svg";

type Props = {
    className?: string;
};

export default function TimelinePage(props: Props) {
    const [stickyTop, setStickyTop] = useState(0);
    const [scrollTarget, setScrollTarget] = useState(false);
    const initialHeight = window.innerHeight / 2 - 30;
    const strings = useStrings();
    const typedStrings: StringsObject = strings;
    const [bgSrc, setBgSrc] = useState(ArgentinaSrc);
    const [country, setCountry] = useState("argentina");
    const basePath = "/images/timeline";


    useEffect(() => {
        let scrollTimer: any;

        const handleScroll = () => {
            clearTimeout(scrollTimer);
            scrollTimer = setTimeout(() => {
                // Replace the following line with your desired callback function
                let activeElement = document.querySelector(".milestone-wrapper.active");
                if (activeElement) {
                    const rect = activeElement.getBoundingClientRect();
                    const distanceFromTop = rect.top;
                    //setScrollTarget(index);
                    const scrollTargetTop = (activeElement as HTMLElement).offsetTop;
                    window.scrollTo({
                        top: scrollTargetTop - 150,
                        behavior: "smooth",
                    });
                    //setScrollTarget(false);  
                }
            }, 100);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            // Clean up the event listener when the component unmounts
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    //@ts-ignore
    const images = require.context(
        "/public/images/timeline",
        false,
        /\.(png|jpe?g|svg)$/
    );

    let imagesList = images.keys();

    useEffect(() => {
        if (country === "italy") {
            setBgSrc(ItalySrc);
        } else {
            setBgSrc(ArgentinaSrc);
        }
    }, [country, bgSrc]);

    const TIMELINE_VALUES = {
        start: initialHeight,
        step: 30,
    };

    useEffect(() => {
        const timeline = document.querySelector(".timeline__nav");
        const items = document.querySelectorAll(".timeline__nav li");
        const milestones = document.querySelectorAll(
            ".timeline__section .milestone-wrapper"
        );
        const offsetTop = parseInt(
            getComputedStyle(timeline as HTMLElement).top
        );

        const handleResize = () => {
            setStickyTop((timeline as HTMLElement).offsetTop - offsetTop);
        };

        const handleScroll = () => {
            if (scrollTarget === false) {
                //height when the new element is triggered
                let viewLine = window.pageYOffset + window.innerHeight / 4;
                let active = -1;

                milestones.forEach((milestone, index) => {
                    if (index !== active) {
                        $(milestone).removeClass("active");
                    }

                    if ((milestone as HTMLElement).offsetTop - viewLine > 0) {
                        return false;
                    }
                    active = index;
                });

                const stringsIndex = active + 1;
                if (
                    typedStrings.hasOwnProperty(
                        `timeline$country${stringsIndex}`
                    )
                ) {
                    const countryKey = `timeline$country${stringsIndex}`;
                    const countryValue = typedStrings[countryKey];
                    if (countryValue !== country) {
                        setCountry(countryValue);
                    }
                }

                milestones[active].classList.add("active");

                (timeline as HTMLElement).style.top =
                    -1 * active * TIMELINE_VALUES.step +
                    TIMELINE_VALUES.start +
                    "px";

                items.forEach((item) => item.classList.remove("active"));
                items[active !== -1 ? active : 0].classList.add("active");
            }
        };

        window.addEventListener("resize", handleResize);
        window.addEventListener("scroll", handleScroll);

        handleResize();
        handleScroll();

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("scroll", handleScroll);
        };
    }, [scrollTarget, TIMELINE_VALUES]);

    const handleItemClick = (index: any) => {
        const items = document.querySelectorAll(".timeline__nav li");
        const milestone = document.querySelector(`#milestone-${index}`);

        if (!items[index].classList.contains("active") && milestone) {
            setScrollTarget(index);

            const scrollTargetTop = (milestone as HTMLElement).offsetTop;

            window.scrollTo({
                top: scrollTargetTop - 150,
                behavior: "smooth",
            });

            setScrollTarget(false);
        }
    };

    const items = [];

    type StringsObject = {
        [key: string]: string;
    };

    let i = 1;
    while (typedStrings.hasOwnProperty(`timeline$year${i}`)) {
        const yearKey = `timeline$year${i}`;
        const descriptionKey = `timeline$description${i}`;

        const yearValue = typedStrings[yearKey];
        const descriptionValue = typedStrings[descriptionKey];

        items.push({ year: yearValue, description: descriptionValue });

        i++;
    }

    return (
        <>
            <Header hasMenuButton={true} />
            <img className="timeline-bg" src={bgSrc} alt="Pagani Vetture" />
            <div className="timeline-line"></div>
            <div className={`page timeline-container`}>
                <nav className="timeline__nav">
                    <ul>
                        {items.map((item, index) => (
                            <li
                                key={index}
                                onClick={() => handleItemClick(index)}
                            >
                                <span> {item.year} </span>
                                <div className="circle"></div>
                                <img className="timeline-point" src={TimelinePoint} alt="Timeline Point" />
                            </li>
                        ))}
                    </ul>
                </nav>
                <div className="timeline-wrapper">
                    <article className="timeline">
                        <section className="timeline__section">
                            <div className="wrapper">
                                {items.map((item, index) => (
                                    <div
                                        className="milestone-wrapper"
                                        key={index}
                                        id={"milestone-" + index}
                                    >
                                            <Swiper
                                                modules={[
                                                    Navigation,
                                                    Pagination,
                                                    Scrollbar,
                                                    A11y,
                                                ]}
                                                slidesPerView={1}
                                                pagination={{ clickable: true }}
                                            >
                                                {imagesList
                                                    .filter((image) => {
                                                        return image.includes(
                                                            item.year
                                                        );
                                                    })
                                                    .map((image, index) => (
                                                        <SwiperSlide
                                                            key={index}
                                                        >
                                                            <img
                                                                className={
                                                                    styles.coverImg
                                                                }
                                                                src={
                                                                    basePath +
                                                                    "/" +
                                                                    image
                                                                }
                                                                alt="Timeline Image"
                                                            />
                                                        </SwiperSlide>
                                                    ))}
                                            </Swiper>
                                        <p className="timeline-desription">
                                            <Interweave content={
                                                item.description
                                            } />
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </section>
                    </article>
                </div>
            </div>
        </>
    );
}
