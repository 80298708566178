import React from 'react'
import styles from "./PrivacyPolicyPage.module.scss";
import { Header } from '../components/Header'
import { SocialFooter } from '../components/SocialFooter'
import { useAtomValue } from 'jotai';
import { localeAtom } from '../localization/atoms/localeAtom';
import { If } from '../mz/types/jsx-control-statements';


export default function PrivacyPolicyPage() {
    const locale = useAtomValue(localeAtom) ?? "it";
  return (
    <>
        <Header hasMenuButton={false}  />
        <div className={`page top-p-header small-horizontal-padding ${styles.page}`}>
            <div className="page-box">
                <If condition={locale == "it"}>
                    <PrivacyPolicyIt />
                </If>
                <If condition={locale == "en"}>
                    <PrivacyPolicyEn />
                </If>
            </div>
        <SocialFooter />    
        </div>
    </>
    

  )
}

function PrivacyPolicyIt()
{
    return(
        <div>
                <h1>Informativa sul trattamento dei dati personali</h1>
                <p className={styles.subtitle}>(resa ai sensi dell’art. 13 Regolamento UE 2016/679)</p>
                <p>
                    La seguente Informativa Privacy Le viene resa dalla società Horacio Pagani S.p.A., con sede in Via dell’Artigianato, 5 – Vill. La Graziosa 41018 San Cesario sul Panaro (MO) Italia, P.IVA: 02054560368 Tel. +39 059 4739201, Email: privacy@pagani.com, (di seguito “Pagani”) in qualità di Titolare del trattamento dei dati personali da Lei forniti o comunque generati nel contesto dell’utilizzo dei servizi resi disponibili tramite questa Web App (di seguito l’”Applicazione”).
                </p>
                <p>
                    Pagani ritiene fondamentale il rispetto della sua privacy e La prega quindi di voler leggere con attenzione questa Informativa. In generale, tutti i dati personali (da qui in avanti “Dati Personali” e/o “Dati”) che fornirà o che verranno raccolti nel contesto dell’utilizzo dell’Applicazione e dei servizi (da qui in avanti “Servizi”) come meglio definiti all’interno della Sezione “Finalità del trattamento dei Dati Personali”, saranno trattati nel rispetto dei principi riconosciuti dalla normativa vigente in materia di protezione dei Dati Personali quali i principi di trasparenza, correttezza, liceità, minimizzazione dei dati, limitazione delle finalità e di conservazione, esattezza, integrità e riservatezza.
                </p>
                <p>La presente Informativa Privacy potrà essere soggetta ad aggiornamenti e La invitiamo pertanto a consultarla periodicamente.</p>
                <h2>INDICE:</h2>
                <ol>
                    <li>Titolare del trattamento</li>
                    <li>Dati personali oggetto di trattamento</li>
                    <li>Finalità del trattamento e base giuridica</li>
                    <li>Destinatari dei Dati Personali</li>
                    <li>Trasferimento dei Dati Personali</li>
                    <li>Conservazione dei Dati Personali</li>
                    <li>Diritti dell’Interessato</li>
                    <li>Diritto di reclamo</li>
                </ol>
                <h2>1. Titolare del trattamento</h2>
                <p>La seguente Informativa viene resa dalla società Horacio Pagani S.p.A., come identificata all’inizio della presente Informativa, che agisce in qualità di Titolare del trattamento relativamente ai Dati Personali trattati attraverso l’Applicazione.</p>
                <h2>2. Dati Personali oggetto di trattamento</h2>
                <p>I Suoi Dati Personali potranno essere raccolti e trattati nel corso della navigazione sull’Applicazione. Il trattamento da parte di Horacio Pagani S.p.A. per mezzo dell’Applicazione. </p>
                <p>Il trattamento da parte di Horacio Pagani S.p.A. per mezzo dell’Applicazione ha ad oggetto esclusivamente l’indirizzo IP dell’utente, con esclusione di altri Dati Personali.</p>

                    <h2>3. Finalità del trattamento e base giuridica</h2>
                    <p>Il trattamento dei Suoi Dati Personali ha la finalità di consentire ai partecipanti a mostre e/o eventi Pagani, organizzate sia presso i locali Pagani che presso terzi, di fruire di contenuti multimediali quali contenuti audio (audioguide), testi e immagini, mediante l’utilizzo dell’Applicazione, per l’intera durata della mostra e/o all’evento.</p>
                    <p>Il trattamento dei Dati per le finalità di adempimento di obblighi contrattuali è strettamente funzionale alla corretta esecuzione del rapporto contrattuale in essere e, pertanto, costituisce condizione necessaria per consentire l’esecuzione di quanto da Lei richiesto (in particolare per le attività volte a consentire la fruizione dell’Applicazione). Conseguentemente, il mancato conferimento dei Dati Personali richiesti al tal fine, ovvero l’accertata erroneità dei dati forniti, potrebbero comportare l’impossibilità per Pagani di assolvere la Sua richiesta o prestarle supporto.</p>

                    <h2>4. Destinatari dei Dati Personali</h2>
                    <p>I Dati personali verranno posti a conoscenza del personale Pagani incaricato e autorizzato al trattamento dei Dati, per il raggiungimento delle finalità precedentemente indicate, che si è impegnato alla riservatezza o ha ricevuto un adeguato obbligo legale di riservatezza.</p>

                    <p>I Dati Personali verranno posti a conoscenza di soggetti terzi designati Responsabili del trattamento (Melazeta S.r.l.) e Sub Responsabili (Seeweb S.r.l.), in conformità alla legge applicabile, che hanno un incarico a trattare i Dati per conto di Pagani.</p>
                    <p>Infine, i Dati verranno comunicati, ove richiesto, ai competenti uffici finanziari e/o altre Pubbliche Amministrazioni,
                    in base a quanto disposto dalle leggi vigenti.</p>
                    <h2>5. Trasferimenti dei Dati Personali</h2>
                    <p>Non è previsto il trasferimento dei Dati Personali a paesi che si trovano al di fuori dell’UE o dello Spazio Economico Europeo,
                    neppure per il tramite del Responsabile e del Sub-Responsabile del trattamento.</p>

                    <h2>6. Conservazione dei Dati Personali</h2>
                    <p>I Dati Personali trattati per la finalità di “Gestione delle richieste” saranno conservati da Pagani per il tempo strettamente
                    funzionale all’esecuzione del Servizio richiesto ed alla corretta esecuzione del rapporto precontrattuale e contrattuale.</p>
                    <p>I Dati Personali trattati per la finalità di “Obblighi di legge” saranno conservati per il periodo previsto dagli specifici
                    obblighi legali o dalla normativa applicabile.</p>
                    <p>I Dati Personali trattati per la finalità di “Sicurezza” saranno conservati da Pagani per il tempo strettamente necessario alla
                    suddetta finalità, tenendo conto di esigenze di conservazione utili per tutelarsi in giudizio o per comunicare tali Dati alle
                    competenti autorità.</p>
                    <h2>7. Diritti dell’interessato</h2>
                    <p>Ai sensi degli artt. 15 – 22 del Regolamento UE 2016/679, a ciascun interessato vengono riconosciuti una serie di diritti, esercitabili in qualsiasi momento e gratuitamente nei confronti di Horacio Pagani S.p.A.</p>
                    <p>In particolare, Lei ha diritto di ottenere, nei casi previsti e con i limiti individuati dalla normativa applicabile, l’accesso ai Suoi Dati Personali, la rettifica, la cancellazione degli stessi o la limitazione del trattamento che la riguarda nonché di opporsi al trattamento (artt. 15 e ss. del Regolamento). Lei ha inoltre il diritto, ove previsto, di richiedere la portabilità dei Dati e, dunque, di ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico, i Dati Personali che La riguardano e ha il diritto di chiederne la trasmissione a un altro titolare.</p>
                    <p>L’apposita istanza per l’esercizio dei diritti a Lei riconosciuti può essere presentata contattando il Titolare ai recapiti indicati all’inizio della presente Informativa Privacy (preferibilmente, a mezzo email al recapito privacy@pagani.com)</p>
                    <p>Il Titolare del trattamento provvederà, nei limiti temporali stabiliti dalla vigente normativa, a fornire tempestiva risposta alle richieste di esercizio dei diritti degli interessati.</p>
                    <h2>8. Diritto di reclamo</h2>
                    <p>Gli interessati che ritengono che il trattamento dei Dati Personali a loro riferiti effettuato attraverso questa Applicazione avvenga in violazione di quanto previsto dal Regolamento, hanno il diritto di proporre reclamo al Garante, come previsto dall’art. 77 del Regolamento, o di adire le opportune sedi giudiziarie (art. 79 del Regolamento).</p>
        </div>
    )
}


function PrivacyPolicyEn (){
    return(
        <div>
            <h1>PERSONAL DATA PROCESSING NOTICE</h1>
            <p className={styles.subtitle}>
            (provided pursuant to art. 13, EU Regulation 2016/679)
            </p>
            <p>
            The following Privacy Notice is provided to you by the company Horacio Pagani S.p.A., with premises at Via dell’Artigianato, 5 – Vill. La Graziosa, 41018, San Cesario sul Panaro (MO), Italy, VAT number: 02054560368 Tel. +39 059 4739201, Email: privacy@pagani.com (hereinafter referred to as “Pagani“) as Data controller in relation to the personal data provided by you or otherwise generated in the context of using the services made available through the Web App (hereinafter referred to as the “Application”).
            </p>
            <p>
            At Pagani, we believe that respect for your privacy is fundamental and therefore we ask you to read this notice carefully. In general, all personal data (hereinafter referred to as the “Personal Data” and/or the “Data“) that you provide or which is collected in the context of Application use and use of the services (hereinafter referred to as the “Services“) – as better defined in the section headed “Personal Data Processing Purposes – will be processed in compliance with the underlying principles of applicable Personal Data protection legislation, such as the principles of transparency, fairness, lawfulness, data minimisation, purpose and retention restrictions, accuracy, integrity, and confidentiality.
            </p>
            <p>
            This Privacy Notice may be updated; therefore, we advise you to re-read it periodically.
            </p>
            <h2>CONTENTS:</h2>
            <ul>
            <li>Data controller</li>
            <li>Data processed</li>
            <li>Purposes of the processing and lawful basis</li>
            <li>Personal Data recipients</li>
            <li>Transfer of Personal Data</li>
            <li>Personal Data retention</li>
            <li>Rights of the data subject</li>
            <li>Right to complain</li>
            </ul>
            <h2>Data controller</h2>
            <p>
            The following notice is provided to you by the company Horacio Pagani S.p.A., as identified at the beginning of this Notice, acting in the capacity of Data controller in relation to the Personal Data processed through the Application.
            </p>
            <h2>Data processed</h2>
            <p>
            Your Personal Data may be collected and processed while you were using the Application. 
            The processing of your Personal Data by Horacio Pagani S.p.A., through the Application, is only concerned the user’s IP address with exclusion of other Personal Data.
            </p>
                 <h2>Purposes of the processing and lawful basis</h2>
                <p>The processing of your Personal Data has the purpose of allowing participants in Pagani expositions and/or events, organized both in Pagani establishments or at third parties, to enjoy multimedia content like audio content (audio guides), texts and images, by using the Application during the entire duration of the exposition and/or the event.</p>
                <p>The Data processing concerns the fulfilment of contractual obligations and it is strictly connected with the proper execution of the current contractual obligation in existence and, therefore is a necessary condition to permit the execution of what you have requested (in particular for the activities designed to permit the use of the Application).</p>
                <p>Consequently, failure to provide the Personal Data required for this purpose, or the provision of Data which is then found to be incorrect, could make it impossible for Pagani to carry out your request, answer your enquiry, or provide you with the assistance sought.</p>
                <h2>Personal Data recipients</h2>
                <p>Personal Data will be disclosed to the Pagani staff appointed and authorised to process the Data for the aforesaid purposes, who have either undertaken to maintain confidentiality or are bound to maintain confidentiality by a legal obligation imposed thereupon.</p>
                <p>The Personal Data will be disclosed to third parties designated as Data Processors (Melazeta S.r.l.) and Sub Processors (Seeweb S.r.l.), in accordance with applicable law as appointed to process Data on behalf of Pagani.</p>
                <p>Finally, when required, Data will be sent to tax offices and/or other public administration authorities in compliance with the laws in force.</p>
                <p>The Personal Data processed will not be published or disseminated.</p>
                <h2>Transfer of Personal Data</h2>
                <p>Is not provided to transfer the Personal Data to countries located outside the EU or the European Economic Area, either through the Data Processors and Sub Processors.</p>
                <h2>Personal Data retention</h2>
                <p>Personal Data processed for the purpose defined as “Management of requests and enquiries” will be kept by Pagani for as long as is strictly necessary to provide the Service requested and to carry out pre-contractual and contractual relations. 
                Personal Data processed for the purpose defined as “Legal Obligations” will be kept for the length of time provided for by specific legal obligations or applicable legislation.
                Personal Data processed for the purpose defined as “Security” will be kept by Pagani for as long as is strictly necessary to fulfil this purpose, taking into account the retention needs relating to defence in court or disclosure of this Data to the competent authorities.</p>
                <h2>Rights of the data subject</h2>
                <p>Pursuant to Arts. 15 – 22 of EU Regulation 2016/679, each data subject has a series of rights which they may exercise against Horacio Pagani S.p.A at any time, free of charge.
                More specifically, you are entitled (in the cases provided for and within the limits set by applicable legislation) to access your Personal Data and have such Data corrected or deleted, as well as to have the processing thereof restricted and to oppose the processing (Arts. 15 et seq. of the Regulation).  You are also entitled (where envisaged) to request Data portability and, therefore, to receive the Personal Data concerning you in a structured, commonly used, machine-readable format and likewise, you are entitled to request such Data be sent to another data controller.</p>
                <p>You can exercise the rights afforded to you by contacting the Data Controller for this specific purpose using the contact details stated at the beginning of this Privacy Notice (preferably by emailing Pagani at the following address: privacy@pagani.com).
                The Data Controller will carry out requests from the Data subject to exercise the rights thereof in a timely manner and, in any case, within the time limits established by legislation in force.</p>
                <h2>Right to complain</h2>
                <p>Data subjects who believe that the way their Personal Data has been processed through this Application breaches the provisions of the Regulation are entitled to file a complaint with the Italian data protection authority, as required by Art. 77 of the Regulations, or to apply to the appropriate judicial authorities (Art. 79 of the Regulation).</p>
            </div>
    )
}
