import React from "react";

import { PanelItem } from "../components/PanelItem";
import { useStrings } from "../localization/hooks/useStrings";
import { useAppNavigate } from "../hooks/useAppNavigate";
import { Header } from "../components/Header";
import { AudioFooter } from "../components/AudioFooter";
import { brandRoute, eventRoute, historyRoute, hypercarRoute, timelineRoute } from "./RoutePath";
import { localeAtom } from "../localization/atoms/localeAtom";
import { useAtomValue } from "jotai";
import { If } from "../mz/types/jsx-control-statements";

type Props = {
    className?: string;
}

export function PanelsPage(props: Props) {
    const strings = useStrings();
    const navigate = useAppNavigate();
    const locale = useAtomValue(localeAtom) ?? "it";

    return (
        <>
            <Header hasMenuButton={false} />
            <div className={`page whiteBg center-align ${props.className ?? ""}`}>
                <div className="page-box">
                    <PanelItem
                        title={strings.common$car_page}
                        onClick={() => navigate(hypercarRoute, { replace: true })}
                    />
                    {/* <PanelItem
                        title={strings.common$dream_page}
                        onClick={() => navigate(historyRoute, { replace: true })}
                    /> */}
                    <PanelItem
                        title={strings.history}
                        onClick={() => navigate(timelineRoute)}
                    />
                    <PanelItem
                        title={strings.event}
                        onClick={() => navigate(eventRoute)}
                    />
                    <PanelItem
                        title={strings.common$partners_page}
                        onClick={() => navigate(brandRoute)}
                    />
                </div>
            </div>
            <AudioFooter />
        </>
    );
}
