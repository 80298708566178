export default {
    "it": {
        "common$select_language": "Scegli la lingua",
        "common$event_details": "Scopri i dettagli dell’evento",
        "common$car_page": "Hypercar esposte",
        "common$dream_page": "La Storia di un Sogno",
        "common$no_car": "Nessuna macchina presente al momento",
        "common$partners_page": "Partner",
        "common$show_more": "Leggi di più",
        "common$show_less": "Nascondi",
        "event$info": "Info evento",
        "event$detalis": "Descrizione",
        "event": "Evento",
        "history": "Storia",
        "history$listen": "Ascolta o leggi l'approfondimento",
        "common$no_brand": "Nessun partner presente al momento",
        "common$error_page_title": "Ops! Qualcosa è andato storto",
        "common$error_page_subtitle": "Per favore, riprova e e il problema persiste, controlla la tua connessione Internet o riprova più tardi. Grazie!"

    },
    "en": {
        "common$select_language": "Select Language",
        "common$event_details": "Event details",
        "common$car_page": "Hypercars",
        "common$dream_page": "Story of a Dream",
        "common$no_car": "No cars present at the moment",
        "common$partners_page": "Partners",
        "common$show_more": "Show more",
        "common$show_less": "Show less",
        "event$info": "Event Info",
        "event$detalis": "Description",
        "event": "Event",
        "history": "History",
        "history$listen": "Listen or read the insight",
        "common$no_brand": "No partners present at the moment",
        "common$error_page_title": "Oops! Something went wrong",
        "common$error_page_subtitle": "Please try again. If the problem persists, check your Internet connection or try again later. Thank you!"
    }
}