import React, { useEffect } from "react";
import { useAtom } from "jotai";
import styles from "./IntroPage.module.scss";
import { localeAtom } from "../localization/atoms/localeAtom";
import { useStrings } from "../localization/hooks/useStrings";
import { Header } from "../components/Header";
import { LanguageButton } from "../components/LanguageButton";
import CoverSrc from "../assets/images/layout/pagani_benvenuto.png";
import { useAppNavigate } from "../hooks/useAppNavigate";
import { errorRoute, eventRoute, hypercarRoute, panelsRoute } from "./RoutePath";
import { Locale } from "../localization/Localization";
import { If } from "../mz/types/jsx-control-statements";
import ReactPlayer from "react-player";
import { SocialFooter } from "../components/SocialFooter";
import { Interweave } from "interweave";
import { lineBreaks } from "../utils/lineBreaks";
import { useEvents } from "../api";
import { getImageFormatUrl } from "../models/ImageModel";

type Props = {
    className?: string
}

export function IntroPage(props: Props) {
    const [locale, setLocale] = useAtom(localeAtom);
    const strings = useStrings();
    const navigate = useAppNavigate();

    const {eventsData, eventsError, eventsLoading} = useEvents();

    useEffect(() => {
        if (eventsError) {
            console.error(eventsError);
            navigate(errorRoute);
        }
    }, [eventsError])

    function handleLanguageClick(newLocale: Locale) {
        setLocale(newLocale);

        if (locale)
            navigate(panelsRoute);
    }

    if (eventsLoading) {
        return (
            <>
                <div className={`page ${styles.page} ${props.className ?? ""}`}>
                    <div className="page-box loading">
                        <div className="lds-dual-ring"></div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <Header alt={true} />
            <div className={`page ${styles.page} ${props.className ?? ""}`}>
                <div className="page-box">
                    <h1 className={`title ${styles.title}`}>
                        {locale === "it" ? eventsData?.title_it : eventsData?.title_en} 
                    </h1>
                    <If condition={eventsData?.video_url != null && eventsData?.featured_image != null}>
                        <ReactPlayer
                            light={<img className={styles.videoCoverImg} src={getImageFormatUrl(eventsData?.featured_image, "big")} alt="Cover Evento" />}
                            url={eventsData?.video_url}
                            width={"100%"}
                            height={"23rem"}
                            controls={false}
                        />
                    </If>
                    <If condition={eventsData?.video_url != null && eventsData?.featured_image == null}>
                        <ReactPlayer
                            url={eventsData?.video_url}
                            width={"100%"}
                            height={"23rem"}
                            controls={false}
                        />
                    </If>
                    <If condition={eventsData?.video_url == null && eventsData?.featured_image != null}>
                        <img className={styles.coverImg} src={getImageFormatUrl(eventsData?.featured_image, "big")} alt="Cover Evento" />
                    </If>
                    <p className={styles.eventDetail} onClick={() => navigate(eventRoute)}>{strings["common$event_details"]}</p>
                    <div className={styles.languageButtons}>
                        <LanguageButton title="ITALIANO" onClick={() => handleLanguageClick("it")} selected={locale === "it"} />
                        <LanguageButton title="ENGLISH" onClick={() => handleLanguageClick("en")} selected={locale === "en"} />
                    </div>
                    <SocialFooter />
                </div>
            </div>
        </>
    );
}
