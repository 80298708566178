import { useAtomValue } from "jotai";

import { localeAtom } from "../atoms/localeAtom";
import { combinedStrings, defaultLocale, interfaceLocale } from "../Localization";

export function useStrings() {

    const locale = useAtomValue(localeAtom);
    return combinedStrings[locale ?? interfaceLocale] ?? combinedStrings[defaultLocale];
}
