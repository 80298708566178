import useSWR from "swr";
import { EventModel } from "./models/EventModel";
import { CarDetail, CarListItemModel } from "./models/CarsModels";
import { BrandDetailModel, BrandModel } from "./models/BrandModels";
import ts from "typescript";
import axios from "axios";

// const stageUrl = "https://pagani-api.melazeta.com/api"
const stageUrl = "https://pagani-api-prod.melazeta.com/api"

export const axiosInstance = axios.create({
  baseURL: stageUrl
});

const fetcher = (url: string) => axiosInstance.get(url).then(res => res.data);

export function useEvents(): { eventsData: EventModel | undefined, eventsError: any, eventsLoading: boolean } {
    const { data, error, isLoading } = useSWR('/events/current', fetcher);
    return { eventsData: data, eventsError: error, eventsLoading: isLoading };
}

export function useCarList(): { carList: CarListItemModel[], carListError: any, carListLoading: boolean } {
    const { data, error, isLoading } = useSWR('/events/cars/list', fetcher);
    return { carList: data, carListError: error, carListLoading: isLoading };
}

export function useCarDetail(id: number): { carDetail: CarDetail, carDetailError: any, carDetailLoading: boolean } {
    const { data, error, isLoading } = useSWR(`/events/cars/${id}/show`, fetcher);
    return { carDetail: data, carDetailError: error, carDetailLoading: isLoading };
}

export function useBrandList(): { brandList: BrandModel[], brandListError: any, brandListLoading: boolean } {
    const { data, error, isLoading } = useSWR('/partners/list', fetcher);
    return { brandList: data, brandListError: error, brandListLoading: isLoading };
}

export function useBrandDetail(id: number): { brandDetail: BrandDetailModel, brandDetailError: any, brandDetailLoading: boolean } {
    const { data, error, isLoading } = useSWR(`/partners/${id}/show`, fetcher);
    return { brandDetail: data, brandDetailError: error, brandDetailLoading: isLoading };
}