import React, { useEffect, useState } from 'react'

import styles from "./CarThumbnail.module.scss";
import imageBack from "../assets/images/icons/icon-back-white.svg";
import { DefaultImageModel, getImageFormatUrl } from '../models/ImageModel';

type Props = {
    carName: string;
    carImg: DefaultImageModel;
    onClick: () => void;
}

export default function CarThumbnail(props: Props) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.imgWrapper} onClick={props.onClick}>
                <img className={styles.img} src={getImageFormatUrl(props.carImg, "big")} alt={props.carName} />
                <div className={styles.arrowContainer}>
                    <img src={imageBack} />
                </div>
            </div>
            <h2 className={styles.name}>{props.carName}</h2>
        </div>
    )
}
