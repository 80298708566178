import { atom } from "jotai";
import { DefaultImageModel } from "../models/ImageModel";

export type ModalAtom = {
    isVisible: boolean;
    img: DefaultImageModel | null;
}

export const modalAtom = atom<ModalAtom>({
    isVisible: false,
    img: null,
});

//set the modal isVisible to the passed value
export const modalVisibilityAtom = atom(null, (get, set, isVisible: boolean) => {
    set(modalAtom, { ...get(modalAtom), isVisible });
} );