import React from "react";
import { useAtom } from "jotai";

import styles from "./AudioFooter.module.scss";
import PlaySrc from "../assets/images/icons/icon-play-button-alt.svg";
import PauseSrc from "../assets/images/icons/icon-pause-button-alt.svg";
import CloseSrc from "../assets/images/icons/icon-down-alt.svg";
import { globalAudioAtom } from "../atoms/audioAtom";
import { useAudioDurationSeconds } from "../mz/hooks/useAudioDurationSeconds";
import { formatSecondsToMmSs } from "../mz/timeUtils";
import { CurrentAudioSeconds } from "./CurrentAudioSeconds";
import { AudioSlider } from "./AudioSlider";

type Props = {
    className?: string;
}

export function AudioFooter(props: Props) {
    const [globalAudio, setGlobalAudio] = useAtom(globalAudioAtom);
    const durationSeconds = useAudioDurationSeconds(globalAudio.element, [globalAudio.audioSrc]);

    return (
        <div
            className={`${styles.wrapper} alt-all ${props.className ?? ""}`}
            style={{ transform: `translateY(${globalAudio.isClosed ? "100%" : "0"})` }}
        >
            <div className={styles.seekControls}>
                <span className={styles.currentTime}>
                    <CurrentAudioSeconds audio={globalAudio.element} />
                </span>
                <AudioSlider
                    audio={globalAudio.element}
                    className={styles.slider}
                />
                <span>
                    {formatSecondsToMmSs(durationSeconds)}
                </span>
            </div>
            <div className={styles.playWrapper}>
                <img
                    src={globalAudio.isPlaying ? PauseSrc : PlaySrc}
                    className={styles.play}
                    onClick={() => {
                        setGlobalAudio(current => ({
                            ...current,
                            isPlaying: !current.isPlaying,
                        }));
                    }}
                />
                <div>
                    <div className={styles.title}>
                        {globalAudio.title}
                    </div>
                    {/* <div className={styles.subtitle}>
                        {globalAudio.subtitle}
                    </div> */}
                </div>
            </div>
            <button
                className={styles.closeButton}
                onClick={() => setGlobalAudio(current => ({
                    ...current,
                    isPlaying: false,
                    isClosed: true,
                }))}
            >
                <img
                    className={styles.closeIcon}
                    src={CloseSrc}
                />
            </button>
        </div>
    );
}
