import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import React, { useEffect } from 'react'
import styles from './CarDetailsPage.module.scss'
import { selectedBrandAtom } from '../atoms/selectedBrandAtom'
import { Header } from '../components/Header'
import Carousel from '../components/Carousel'
import { If } from '../mz/types/jsx-control-statements'
import { SwiperSlide } from 'swiper/react'
import { SocialFooter } from '../components/SocialFooter'
import { localeAtom } from '../localization/atoms/localeAtom'
import ReactPlayer from 'react-player'
import { modalAtom } from '../atoms/modalAtom'
import { useAppNavigate } from '../hooks/useAppNavigate'
import { brandRoute, hypercarRoute } from './RoutePath'
import ScrollToTop from '../components/ScrollToTop'
import { lineBreaks } from '../utils/lineBreaks'
import { Interweave } from 'interweave'
import { useBrandDetail } from '../api'
import { DefaultImageModel, getImageFormatUrl } from '../models/ImageModel'

export default function CarDetailsPage() {
    const locale = useAtomValue(localeAtom) ?? "it";
    const selectedBrand = useAtomValue(selectedBrandAtom);
    const setModal = useSetAtom(modalAtom);
    const navigate = useAppNavigate();

    useEffect(() => {
        if (!selectedBrand)
            navigate(brandRoute);
    }, [selectedBrand])

    const { brandDetail, brandDetailError, brandDetailLoading } = selectedBrand?.id ? useBrandDetail(selectedBrand?.id) : { brandDetail: undefined, brandDetailError: undefined, brandDetailLoading: false };

    useEffect(() => {
        if (brandDetailError) {
            console.error(brandDetailError);
            navigate(brandDetailError);
        }
    }, [brandDetailError])

    if (brandDetailLoading || !brandDetail) {
        return (
            <>
                <div className={`page ${styles.page}`}>
                    <div className="page-box loading">
                        <div className="lds-dual-ring"></div>
                    </div>
                </div>
            </>
        );
    }
    return (
        <>
            <Header hasMenuButton={false} />
            <div className="page small-horizontal-padding">
                {brandDetail &&
                    <div className="page-box">
                        <p className={styles.partnerTitle} style={{ textAlign: 'left' }}> <Interweave content={lineBreaks(locale == "it" ? brandDetail.title_it : brandDetail.title_en)} /> </p>
                        <p className={styles.partnerTitle} style={{ textAlign: 'left' }}> <Interweave content={lineBreaks(locale == "it" ? brandDetail.subtitle_it : brandDetail.subtitle_en)} /> </p>
                        <If condition={brandDetail?.image_gallery.length > 0}>
                            <Carousel>
                                {brandDetail.image_gallery.map((img: DefaultImageModel, index: number) =>
                                    <SwiperSlide key={index}>
                                        <img key={index} src={getImageFormatUrl(img, "small")} className={styles.carouselImg} onClick={() => setModal({ isVisible: true, img })} />
                                    </SwiperSlide>
                                )}
                            </Carousel>
                        </If>
                        <If condition={(locale == "it" && brandDetail.description_it !== null) || (locale == "en" && brandDetail.description_en !== null)}>
                            <p className={styles.carDescription}><Interweave content={lineBreaks(locale == "it" ? brandDetail.description_it : brandDetail.description_en)} /></p>
                        </If>

                        <If condition={brandDetail.video_url !== null}>
                            <div style={{ marginBottom: "2rem" }}>
                                <ReactPlayer
                                    url={brandDetail.video_url}
                                    width={"100%"}
                                    height={"23rem"}
                                    controls={true}
                                />
                            </div>
                        </If>
                        <SocialFooter />
                        <ScrollToTop />

                    </div>}
            </div>
        </>
    )
}
