import { useAtomValue, useSetAtom } from 'jotai';
import React from 'react'
import { modalAtom, modalVisibilityAtom } from '../atoms/modalAtom';
import { If } from '../mz/types/jsx-control-statements';
import styles from './Modal.module.scss'
import closeIcon from '../assets/images/icons/icon-close.svg'
import { getImageFormatUrl } from '../models/ImageModel';

export default function Modal() {
    const modal = useAtomValue(modalAtom);
    const setVisible = useSetAtom(modalVisibilityAtom);

    return (
        <>
            <If condition={modal.isVisible}>
                <div className={styles.modal}>
                    <div className={styles.modalOverlay} onClick={() => setVisible(false)} />
                    <img src={closeIcon} className={styles.closeIcon} onClick={() => setVisible(false)} />
                    <div className={styles.modalContent}>
                        <img src={getImageFormatUrl(modal.img, "big")} />
                    </div>
                </div>
            </If>
        </>
    );
}

