import React, { useEffect, useState } from 'react';
import styles from "./HypercarPage.module.scss";
import { Header } from '../components/Header';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { SocialFooter } from '../components/SocialFooter';
import { localeAtom } from '../localization/atoms/localeAtom';
import { If } from '../mz/types/jsx-control-statements';
import { useStrings } from '../localization/hooks/useStrings';
import { selectedBrandAtom } from '../atoms/selectedBrandAtom';
import { brandDetailRoute } from './RoutePath';
import { useAppNavigate } from '../hooks/useAppNavigate';
import PartnerThumbnail from '../components/PartnerThumbnail';
import { useBrandList } from '../api';
import { BrandModel } from '../models/BrandModels';

export default function BrandPage() {
    const locale = useAtomValue(localeAtom) ?? "it";
    const strings = useStrings();
    const [selectedBrand, setSelectedBrand] = useAtom(selectedBrandAtom);
    const navigate = useAppNavigate();

    function onBrandClick(brand: BrandModel) {
        if (!brand.has_detail)
            return;
        setSelectedBrand(brand);
        navigate(brandDetailRoute, { replace: false });
    }

    const { brandList, brandListError, brandListLoading } = useBrandList();

    useEffect(() => {
        if (brandListError) {
            console.error(brandListError);
            navigate(brandListError);
        }
    }, [brandListError])

    if (brandListLoading && !brandList) {
        return (
            <>
                <div className={`page ${styles.page}`}>
                    <div className="page-box loading">
                        <div className="lds-dual-ring"></div>
                    </div>
                </div>
            </>
        );
    }
    return (
        <>
            <Header hasMenuButton={true} />
            <div className="page small-horizontal-padding">
                <div className="page-box partner">
                    <If condition={brandList.length > 0}>
                        {brandList.map((brand, index) => <PartnerThumbnail key={index} partnerName={brand.name} partnerImg={brand.list_image} onClick={() => onBrandClick(brand) } clickable={brand.has_detail} />)}
                    </If>
                    {<If condition={brandList.length <= 0}>
                        <p style={{ textAlign: "center" }}>{strings.common$no_brand}</p>
                    </If>}
                </div>
                <SocialFooter />
            </div>
        </>
    )
}
