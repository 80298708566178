
import React, { useEffect, useState } from 'react'
import { Header } from '../components/Header'
import styles from "./HypercarPage.module.scss";
import CarThumbnail from '../components/CarThumbnail';
import { If } from '../mz/types/jsx-control-statements';
import { useAtomValue, useSetAtom } from 'jotai';
import { selectedCarAtom } from '../atoms/selectedCarAtom';
import { useStrings } from '../localization/hooks/useStrings';
import { SocialFooter } from '../components/SocialFooter';
import { useAppNavigate } from '../hooks/useAppNavigate';
import { errorRoute, hypercarDetailRoute } from './RoutePath';
import { localeAtom } from '../localization/atoms/localeAtom';
import { Interweave } from 'interweave';
import { lineBreaks } from '../utils/lineBreaks';
import { useCarList, useEvents } from '../api';
import { getImageFormatUrl } from '../models/ImageModel';
import { CarListItemModel } from '../models/CarsModels';

export default function HypercarPage() {
    const setSelectedCar = useSetAtom(selectedCarAtom);
    const strings = useStrings();
    const navigate = useAppNavigate();
    const { eventsData, eventsError, eventsLoading } = useEvents();
    const photoBanner = eventsData?.hypercar_photo_banner ?? false;
    const [carsInfo, setCarsInfo] = useState<CarListItemModel[]>([]);

    const locale = useAtomValue(localeAtom) ?? "it";

    const {carList, carListError, carListLoading} = useCarList();

    useEffect(() => {
        if (carList)
            setCarsInfo(carList);
    }, [carList])

    useEffect(() => {
        if (eventsError || carListError) {
            console.error(eventsError);
            navigate(errorRoute);
        }
    }, [eventsError, carListError])

    function onCarClick(car: CarListItemModel) {
        setSelectedCar(car);
        navigate(hypercarDetailRoute, { replace: false });
    }

    if (eventsLoading || carListLoading) {
        return (
            <>
                <div className={`page ${styles.page}`}>
                    <div className="page-box loading">
                        <div className="lds-dual-ring"></div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <Header hasMenuButton={true} />
            <div className="page top-p-header small-horizontal-padding">
                <div className="page-box">
                    <div className={`${styles.header} ${photoBanner ? '' : styles.headerPictureless}`}>
                        <div className={styles.headerTitle}>
                            <h1 className={`title ${styles.title}`}>
                                {locale === "it" ? eventsData?.title_it : eventsData?.title_en}
                            </h1>
                        </div>
                        <If condition={photoBanner}>
                            <img className={styles.coverImg} src={getImageFormatUrl(eventsData?.featured_image, "small")} alt="Cover Evento" />
                        </If>
                    </div>

                    <If condition={carsInfo.length > 0}>
                        {carsInfo.map((car, index) => <CarThumbnail key={index} carName={car.car.model?? ""} carImg={car.car.list_image} onClick={() => onCarClick(car)} />)}
                    </If>
                    <If condition={carsInfo.length <= 0}>
                        <p style={{ textAlign: "center" }}>{strings["common$no_car"]}</p>
                    </If>
                    <SocialFooter />
                </div>
            </div>
        </>
    )
}
