import React from "react";

import styles from "./LanguageButton.module.scss";
import imageBack from "../assets/images/icons/icon-back-white.svg";

type Props = {
    title: string;
    selected: boolean;
    onClick?: () => void;
    className?: string;
}

export function LanguageButton(props: Props) {
    return (
        <div className={`${styles.wrapper} ${props.className ?? ""} ${props.selected ? styles.selected : ''}`} onClick={props.onClick}>
            <div className={styles.title}>
                {props.title}
            </div>
            <div className={styles.arrow}>
                <img src={imageBack} />
            </div>
        </div>
    );
}
