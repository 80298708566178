import React, { useEffect, useState } from 'react'
import styles from "./PartnerThumbnail.module.scss";
import imageBack from "../assets/images/icons/icon-back-white.svg";
import { If } from '../mz/types/jsx-control-statements';
import { DefaultImageModel, getImageFormatUrl } from '../models/ImageModel';

type Props = {
    partnerName: string;
    partnerImg ?: DefaultImageModel;
    clickable : boolean;
    onClick: () => void;
}

export default function PartnerThumbnail(props: Props) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.imgWrapper} onClick={props.onClick}>
                <img className={`${styles.img} ${props.clickable ? "" : styles['no-shadow']} `} src={getImageFormatUrl(props.partnerImg, "big")} alt={props.partnerName} />
                <If condition={props.clickable}>
                    <img className={styles.arrow} src={imageBack} />
                </If>
            </div>
        </div>
    )
}
