export type ImageFormatModel = {
    url: string;
    width: number;
    height: number;
}

export type defaultImageFormats = "reference" | "small" | "medium" | "big";

export type ImageModel<T extends string> = Record<T | "reference", ImageFormatModel>;

export type DefaultImageModel = ImageModel<defaultImageFormats>;

export function getImageFormat<T extends string>(image: ImageModel<T> | undefined | null, format: T) {
    if (image)
        return image[format] ?? image.reference;
    else
        return undefined;
}

export function getImageFormatUrl<T extends string>(image: ImageModel<T> | undefined | null, format: T) {
    return getImageFormat(image, format)?.url;
}