import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import styles from './CarDetailsPage.module.scss'
import { selectedCarAtom } from '../atoms/selectedCarAtom'
import { Header } from '../components/Header'
import Carousel from '../components/Carousel'
import { If } from '../mz/types/jsx-control-statements'
import { SwiperSlide } from 'swiper/react'
import carCircle from '../assets/images/layout/car-circle.svg'
import { SocialFooter } from '../components/SocialFooter'
import { localeAtom } from '../localization/atoms/localeAtom'
import ReactPlayer from 'react-player'
import { modalAtom } from '../atoms/modalAtom'
import { useAppNavigate } from '../hooks/useAppNavigate'
import { errorRoute, hypercarRoute } from './RoutePath'
import ScrollToTop from '../components/ScrollToTop'
import { lineBreaks } from '../utils/lineBreaks'
import { Interweave } from 'interweave'
import { useStrings } from "../localization/hooks/useStrings";
import { useCarDetail } from '../api'
import { DefaultImageModel, getImageFormatUrl } from '../models/ImageModel'
import { CarPartner } from '../models/CarsModels'

export default function CarDetailsPage() {
    const locale = useAtomValue(localeAtom) ?? "it";
    const strings = useStrings();
    const selectedCar = useAtomValue(selectedCarAtom);
    const setModal = useSetAtom(modalAtom);
    const navigate = useAppNavigate();
    const [showFullDescription, setShowFullDescription] = useState(false);
    const showMoreLabel = showFullDescription ? strings.common$show_less : strings.common$show_more;

    const { carDetail, carDetailError, carDetailLoading } = selectedCar?.id ? useCarDetail(selectedCar?.id) : { carDetail: undefined, carDetailError: undefined, carDetailLoading: false };

    const [selectedPartner, setSelectedPartner] = useState<CarPartner>();

    useEffect(() => {
        if (carDetailError) {
            console.error(carDetailError);
            navigate(errorRoute);
        }
    }, [carDetailError])

    useEffect(() => {
        if (!selectedCar)
            navigate(hypercarRoute);
    }, [selectedCar, locale])

    useEffect(() => {
        if (carDetail && carDetail.car.car_partners && selectedCar) {
            setSelectedPartner(carDetail.car.car_partners[0]);
        }
    }, [carDetail])

    const toggleDescriptionVisibility = () => {
        setShowFullDescription(!showFullDescription);
    };

const getDescriptionToShow = (locale: string) => {
        if (showFullDescription) {
            return locale == "it" ? carDetail?.car.description_it : carDetail?.car.description_en;
        } else {
            // Show only the first few lines of the description
            // return (selectedCar![`descrizione_${locale}`]?.split('\n')[0] + '..');
            return locale == "it" ? carDetail?.car.description_it.split('<br />')[0] + '...' : carDetail?.car.description_en.split('<br />')[0] + '...';
        }
    };

    if (carDetailLoading || !carDetail) {
        return (
            <>
                <div className={`page ${styles.page}`}>
                    <div className="page-box loading">
                        <div className="lds-dual-ring"></div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <Header hasMenuButton={false} />
            <div className="page top-p-header small-horizontal-padding">
                <div className="page-box" style={{ textAlign: 'center' }}>
                    <img className={styles.logo} src={getImageFormatUrl(carDetail.car.logo, "small")} alt="Logo" />
                    <If condition={carDetail.slideshow_enabled && carDetail.car.image_gallery !== null}>
                        <Carousel>
                            {carDetail.car.image_gallery.map((img: DefaultImageModel, index: number) =>
                                <SwiperSlide key={index}>
                                    <img src={getImageFormatUrl(img, "medium")} className={styles.carouselImg} onClick={() => setModal({ isVisible: true, img })} />
                                </SwiperSlide>
                            )}
                        </Carousel>
                        <img src={carCircle} className={styles.carCircle} />
                    </If>
                    {locale == "it" && <Carousel slidesPerView={1} pagination={true}>
                        {carDetail.car.technical_specification_values_it.map((spec: any, index: number) => (
                            <SwiperSlide key={index}>
                                <div className={styles.tecnicalDataElement}>
                                    <p className={styles.tecnicalDataTitle}>{spec.technical_specification_name.name}</p>
                                    <p className={styles.tecnicalDataValue}>{spec.value}</p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Carousel>}
                    {locale == "en" && <Carousel slidesPerView={1} pagination={true}>
                        {carDetail.car.technical_specification_values_en.map((spec: any, index: number) => (
                            <SwiperSlide key={index}>
                                <div className={styles.tecnicalDataElement}>
                                    <p className={styles.tecnicalDataTitle}>{spec.technical_specification_name.name}</p>
                                    <p className={styles.tecnicalDataValue}>{spec.value}</p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Carousel>}
                        <div>
                            <div className={styles.carDescription}>
                                <Interweave content={lineBreaks(getDescriptionToShow(locale) ?? "")} />
                                <p className={styles.showMore} onClick={toggleDescriptionVisibility}>{showMoreLabel}</p>
                            </div>
                        </div>
                    <If condition={carDetail.car.video_urls.length > 0}>
                        {carDetail.car.video_urls.map((video: string, index: number) =>
                            <div style={{ marginBottom: "4rem" }} key={index}>
                                <ReactPlayer
                                    url={video}
                                    width={"100%"}
                                    height={"23rem"}
                                    controls={true}
                                />
                            </div>
                        )}
                    </If>
                    {selectedPartner &&
                        <div>
                            <div className={styles.partnerTitleContainer}>
                                {carDetail.car.car_partners.map((partner: CarPartner, index) => (
                                    <div key={index} className={`${styles.partnerTitle} ${partner == selectedPartner ? styles.selectedPartner : ""}`} onClick={() => setSelectedPartner(partner)}>{partner.name}</div>
                                ))}
                            </div>
                            <div style={{ marginTop: "2.4rem" }}>
                                <Carousel>
                                    {selectedPartner.image_gallery.map((img: DefaultImageModel, index: number) => (
                                        <SwiperSlide key={index+"partner_img"}>
                                            <img
                                                src={getImageFormatUrl(img, "small")}
                                                className={styles.carouselImg}
                                                onClick={() => setModal({ isVisible: true, img })}
                                            />
                                        </SwiperSlide>
                                    ))}
                                </Carousel>
                            </div>
                            <p style={{ textAlign: "left" }} className={styles.partnerDescription}>
                                <Interweave content={locale == "it" ? selectedPartner.description_it : selectedPartner.description_en} />
                            </p>
                            {selectedPartner.video_url &&
                                <div style={{ marginBottom: "4rem" }}>
                                    <ReactPlayer
                                        url={selectedPartner.video_url}
                                        width={"100%"}
                                        height={"23rem"}
                                        controls={true}
                                    />
                                </div>
                            }

                        </div>
                    }
                    <SocialFooter />
                    <ScrollToTop />
                </div>
            </div>
        </>
    )
}
