import React, { useEffect } from 'react'
import styles from "./EventPage.module.scss";
import { Header } from '../components/Header'
import { If } from '../mz/types/jsx-control-statements';
import { useAtomValue } from 'jotai';
import { localeAtom } from '../localization/atoms/localeAtom';
import { useStrings } from '../localization/hooks/useStrings';
import { Interweave } from 'interweave';
import { lineBreaks } from '../utils/lineBreaks';
import { useEvents } from '../api';
import { getImageFormatUrl } from '../models/ImageModel';
import { errorRoute } from './RoutePath';
import { useAppNavigate } from '../hooks/useAppNavigate';

export default function EventPage() {
    const locale = useAtomValue(localeAtom) ?? "it";
    const strings = useStrings();
    const navigate = useAppNavigate();

    const {eventsData, eventsError, eventsLoading} = useEvents();

    useEffect(() => {
        if (eventsError) {
            console.error(eventsError);
            navigate(errorRoute);
        }
    }, [eventsError])

    if (eventsLoading) {
        return (
            <>
                <div className={`page ${styles.page}`}>
                    <div className="page-box loading">
                        <div className="lds-dual-ring"></div>
                    </div>
                </div>
            </>
        );
    } else if (eventsData) return (
        <>
            <Header hasMenuButton={true} />
            <div className={`page ${styles.page}`}>
                <div className="page-box">

                    <p className={styles.sectionTitle}>{strings["event"]}</p>
                    <h1 className={styles.title}>
                        <Interweave content={lineBreaks(locale =="it" ? eventsData.title_it : eventsData.title_en) ?? ""}></Interweave>
                    </h1>
                    <If condition={eventsData.featured_image != null}>
                        <img className={styles.coverImg} src={getImageFormatUrl(eventsData?.featured_image, "big")} />
                    </If>

                    {locale == "it" && 
                    <If condition={eventsData.info_it != null}>
                        <p className={styles.eventText}>{eventsData.info_it}</p>
                    </If>}

                    {locale == "en" && 
                    <If condition={eventsData.info_en != null}>
                        <p className={styles.eventText}>{eventsData.info_en}</p>
                    </If>}

                    <Interweave className={styles.eventText} content={locale =="it" ? eventsData.description_it : eventsData.description_en} />
                </div>
            </div>
        </>
    );
    else return (
        <>
            <div className={`page ${styles.page}`}>
                <div className="page-box loading">
                    <div className="lds-dual-ring"></div>
                </div>
            </div>
        </>
    );
}
