import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { AppRoute } from "../components/AppRoute";
import { AudioFooter } from "../components/AudioFooter";
import { GlobalAudio } from "../components/GlobalAudio";
import Modal from "../components/Modal";
import CarDetailsPage from "./CarDetailsPage";
import EventPage from "./EventPage";
import HypercarPage from "./HypercarPage";
import { IntroPage } from "./IntroPage";
import { LanguagePage } from "./LanguagePage";
import { PanelsPage } from "./PanelsPage";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import TimelinePage from "./TimelinePage";
import TimelinePageStandalone from "./TimelinePageStandalone";
import { LanguageStandalonePage } from "./LanguageStandalonePage";
import { languageRoute, hypercarRoute, panelsRoute, historyRoute, histotyDetailRoute, eventRoute, hypercarDetailRoute, privacyRoute, timelineRoute, timelineStandaloneRoute, languageStandaloneRoute, brandRoute, brandDetailRoute, errorRoute } from "./RoutePath";
import BrandPage from "./BrandPage";
import BrandDetailsPage from "./BrandDetailsPage";
import ErrorPage from "./ErrorPage";

console.log(process.env.PUBLIC_URL);
console.log(process.env.NODE_ENV);

export function MainPage() {
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Routes>
                <AppRoute
                    path="/"
                    element={<IntroPage />}
                />
                <AppRoute
                    path={languageRoute}
                    element={<LanguagePage />}
                />
                <AppRoute
                    path={eventRoute}
                    element={<EventPage />}
                />
                <AppRoute
                    path={panelsRoute}
                    element={<PanelsPage />}
                />
                <AppRoute
                    path={hypercarRoute}
                    element={<HypercarPage />}
                />
                <AppRoute
                    path={hypercarDetailRoute}
                    element={<CarDetailsPage />}
                />
                {/* <AppRoute
                    path={historyRoute}
                    element={<HistoryPage />}
                />
                <AppRoute
                    path={histotyDetailRoute}
                    element={<HistoryDetailPage />}
                /> */}
                <AppRoute
                    path={privacyRoute}
                    element={<PrivacyPolicyPage />}
                />
                <AppRoute
                    path={timelineRoute}
                    element={<TimelinePage />}
                />
                <AppRoute
                    path={timelineStandaloneRoute}
                    element={<TimelinePageStandalone />}
                />
                <AppRoute
                    path={languageStandaloneRoute}
                    element={<LanguageStandalonePage />}
                />
                <AppRoute
                    path={brandRoute}
                    element={<BrandPage />}
                />
                <AppRoute
                    path={brandDetailRoute}
                    element={<BrandDetailsPage />}
                />
                <AppRoute
                    path={errorRoute}
                    element={<ErrorPage />}
                />
                <Route
                    path="/*"
                    element={<Navigate to="/" />}
                />

            </Routes>
            <GlobalAudio />
            <AudioFooter />
            <Modal />
        </BrowserRouter>
    );
}
