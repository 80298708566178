export default {
    "it": {
        "history_item0$title": "Alle origini del sogno",
        "history_item0$abstract": "Questa è la storia di un sognatore che ha trasformato le Supercar in Hypercar.\n\nTutto ha inizio nel 1955 a Casilda, nel cuore della campagna argentina. Horacio Pagani  ama giocare all’aperto e ha una così grande passione per le automobili che passa il suo tempo a realizzare modellini in miniatura. \n\nIl suo sogno è andare a Modena, per poter progettare le sue auto. Un giorno, mentre legge il Reader’s Digest, Horacio scopre una delle più grandi menti di tutti i tempi, Leonardo da Vinci, secondo il quale “Arte e Scienza sono due discipline che devono camminare mano nella mano”. Questa massima apre per Horacio Pagani una nuova strada e da quel momento in poi, i principi di Da Vinci ispirano ogni creazione, persino i suoi studi.",
        "history_item0$image": "st-1",
        "history_item0$subtitle": "Come la filosofia di Leonardo da Vinci su Arte e Scienza ha rivoluzionato la vita di Horacio Pagani.",
        "history_item0$transcription": "Questa è la storia di un sognatore che ha trasformato le Supercar in Hypercar.\n\nTutto ha inizio nel 1955 a Casilda, nel cuore della campagna argentina. Horacio Pagani  ama giocare all’aperto e ha una così grande passione per le automobili che passa il suo tempo a realizzare modellini in miniatura. \n\nIl suo sogno è andare a Modena, per poter progettare le sue auto. Un giorno, mentre legge il Reader’s Digest, Horacio scopre una delle più grandi menti di tutti i tempi, Leonardo da Vinci, secondo il quale “Arte e Scienza sono due discipline che devono camminare mano nella mano”. Questa massima apre per Horacio Pagani una nuova strada e da quel momento in poi, i principi di Da Vinci ispirano ogni creazione, persino i suoi studi.",
        "history_item0$audio": "origins_it.mp3",
        "history_item1$title": "Gli anni alla Lamborghini",
        "history_item1$abstract": "Una tenda, due biciclette e una valigia piena di sogni. È tutto quello che possiedono Horacio Pagani e la giovane moglie Cristina quando decidono di partire per l’Italia nel dicembre del 1982. Neppure le lettere di referenze che Fangio scrive, introducendo Horacio alle principali case automobilistiche italiane, riescono a garantirgli un impiego. \nSolo dopo diversi mesi, il reparto Esperienza Carrozzeria di Automobili Lamborghini lo assume come operaio. Pur partendo dal basso, riesce presto a dimostrare il suo valore e nel 1985 gli viene affidato il nuovo reparto Materiali Compositi, in cui prende parte alla progettazione della Countach Evoluzione, che indica a Horacio la strada verso il futuro delle auto sportive.",
        "history_item1$image": "st-2",
        "history_item1$subtitle": "Lasciarsi tutto alle spalle per inseguire un sogno. È ciò che fa Horacio Pagani, prendendo la decisione più folle e insieme logica della sua vita.",
        "history_item1$transcription": "Una tenda, due biciclette e una valigia piena di sogni. È tutto quello che possiedono Horacio Pagani e la giovane moglie Cristina quando decidono di partire per l’Italia nel dicembre del 1982. Neppure le lettere di referenze che Fangio scrive, introducendo Horacio alle principali case automobilistiche italiane, riescono a garantirgli un impiego. \nSolo dopo diversi mesi, il reparto Esperienza Carrozzeria di Automobili Lamborghini lo assume come operaio. Pur partendo dal basso, riesce presto a dimostrare il suo valore e nel 1985 gli viene affidato il nuovo reparto Materiali Compositi, in cui prende parte alla progettazione della Countach Evoluzione, che indica a Horacio la strada verso il futuro delle auto sportive.",
        "history_item1$audio": "years_it.mp3",
        "history_item2$title": "Modena Design",
        "history_item2$abstract": "Verso la fine degli anni ‘80, proprio quando la crisi economica sembra infliggere i colpi più duri all’economia globale, Horacio decide di puntare tutto sulla realizzazione del suo sogno, ad ogni costo, e fonda Modena Design. Durante il giorno realizza compositi all’avanguardia per nomi di spicco, come Renault, Daihatsu, Ferrari Formula 1, Aprilia e Dallara. Ma è di notte che Horacio Pagani lavora alla propria auto, che presto sarà battezzata “Fangio F1”, in onore del suo grande amico, ma nel marzo del 1999, l’automobile viene presentata ufficialmente a Ginevra con il nome di Zonda. è, sotto ogni aspetto, una Hypercar. Viene da un’altra dimensione, è figlia del desiderio di suscitare emozioni fin dal primo istante.",
        "history_item2$subtitle": "Quando il gioco si fa duro, i duri cominciano a giocare dando vita ai loro sogni.",
        "history_item2$image": "st-3",
        "history_item2$transcription": "Verso la fine degli anni ‘80, proprio quando la crisi economica sembra infliggere i colpi più duri all’economia globale, Horacio decide di puntare tutto sulla realizzazione del suo sogno, ad ogni costo, e fonda Modena Design. Durante il giorno realizza compositi all’avanguardia per nomi di spicco, come Renault, Daihatsu, Ferrari Formula 1, Aprilia e Dallara. Ma è di notte che Horacio Pagani lavora alla propria auto, che presto sarà battezzata “Fangio F1”, in onore del suo grande amico, ma nel marzo del 1999, l’automobile viene presentata ufficialmente a Ginevra con il nome di Zonda. è, sotto ogni aspetto, una Hypercar. Viene da un’altra dimensione, è figlia del desiderio di suscitare emozioni fin dal primo istante.",
        "history_item2$audio": "design_it.mp3",
        "history_item3$title": "Pagani Automobili",
        "history_item3$abstract": "Il segreto di Pagani Automobili, l’atelier “dove le Hypercar sono personalizzate in base ai desideri dei loro proprietari”, è che il sogno di un ragazzo argentino è diventato oggi il sogno delle donne e degli uomini che ogni giorno si impegnano a realizzarlo. Creare automobili personalizzate, oggetti unici in grado di trasmettere emozioni non è certo cosa da poco. Eppure, non esiste un veicolo firmato Pagani che, negli anni, non sia stato ispirato dalla più avanzata ricerca scientifica e progettuale. Il motore di Modena Design prima, e Pagani Automobili poi, è - ed è sempre stato - la ricerca dell’unicità nella creazione di automobili che incarnino l’idea di Da Vinci secondo cui Arte e Scienza camminano mano nella mano.",
        "history_item3$subtitle": "L’Atelier Pagani dove un unico sogno dà vita a tanti altri.",
        "history_item3$image": "st-4",
        "history_item3$transcription": "Il segreto di Pagani Automobili, l’atelier “dove le Hypercar sono personalizzate in base ai desideri dei loro proprietari”, è che il sogno di un ragazzo argentino è diventato oggi il sogno delle donne e degli uomini che ogni giorno si impegnano a realizzarlo. Creare automobili personalizzate, oggetti unici in grado di trasmettere emozioni non è certo cosa da poco. Eppure, non esiste un veicolo firmato Pagani che, negli anni, non sia stato ispirato dalla più avanzata ricerca scientifica e progettuale. Il motore di Modena Design prima, e Pagani Automobili poi, è - ed è sempre stato - la ricerca dell’unicità nella creazione di automobili che incarnino l’idea di Da Vinci secondo cui Arte e Scienza camminano mano nella mano.",
        "history_item3$audio": "cars_it.mp3",
        "history_item4$title": "Il Centro di Ricerca Arte e Scienza",
        "history_item4$abstract": "Nel luogo dove tutto ha avuto inizio, oggi si guarda al futuro. La sede storica della ex Modena Design si è trasformata nel centro di ricerca chiamato “Arte e Scienza”, il principio fondamentale alla base delle creazioni di Pagani Automobili, che trae ispirazione dal genio di Leonardo da Vinci. \n\nLa sede storica di via dell’Artigianato oggi è un centro tecnologico e creativo, in grado di unire sotto lo stesso tetto le diverse discipline coinvolte nella produzione di automobili esclusive, ispirate in egual misura dalla bellezza e dalla ricerca scientifica. Come l’officina Rinascimento, il Centro è una fucina di idee, dove le competenze e il know-how si fondono, contaminandosi e alimentandosi a vicenda, in uno scambio continuo di dati e informazioni.",
        "history_item4$subtitle": "Un luogo dedicato alla creatività, ideato per stimolare la condivisione di idee e competenze.",
        "history_item4$image": "st-5",
        "history_item4$transcription": "Nel luogo dove tutto ha avuto inizio, oggi si guarda al futuro. La sede storica della ex Modena Design si è trasformata nel centro di ricerca chiamato “Arte e Scienza”, il principio fondamentale alla base delle creazioni di Pagani Automobili, che trae ispirazione dal genio di Leonardo da Vinci. \n\nLa sede storica di via dell’Artigianato oggi è un centro tecnologico e creativo, in grado di unire sotto lo stesso tetto le diverse discipline coinvolte nella produzione di automobili esclusive, ispirate in egual misura dalla bellezza e dalla ricerca scientifica. Come l’officina Rinascimento, il Centro è una fucina di idee, dove le competenze e il know-how si fondono, contaminandosi e alimentandosi a vicenda, in uno scambio continuo di dati e informazioni.",
        "history_item4$audio": "research_it.mp3",
        "history_item5$title": "La fibra di carbonio",
        "history_item5$abstract": "Durante la realizzazione della Countach Evoluzione di Lamborghini, Horacio comprende l’alto potenziale dei sistemi basati sui materiali compositi e, in particolare, della fibra di carbonio. \n\nLa ricerca sui materiali compositi avanzati si tramuta nella sua missione fin dal momento dell’acquisto della prima autoclave, negli anni ‘80. Una rivoluzione inarrestabile e in continua evoluzione, diventata un metodo di lavorazione molto specifico e preciso, che necessita di personale altamente qualificato. \n\nIl cuore pulsante della maestria di Pagani è racchiuso in uno dei suoi tratti distintivi ed è ciò che salta subito all’occhio davanti a una Zonda o a una Huayra: il motivo spinato sul cofano anteriore, che simboleggia non solo la ricerca tecnica, ma anche quella estetica…",
        "history_item5$subtitle": "Quel motivo spinato simbolo della maestria Pagani frutto di ricerca tecnica ed estetica…",
        "history_item5$image": "st-6",
        "history_item5$transcription": "Durante la realizzazione della Countach Evoluzione di Lamborghini, Horacio comprende l’alto potenziale dei sistemi basati sui materiali compositi e, in particolare, della fibra di carbonio. \n\nLa ricerca sui materiali compositi avanzati si tramuta nella sua missione fin dal momento dell’acquisto della prima autoclave, negli anni ‘80. Una rivoluzione inarrestabile e in continua evoluzione, diventata un metodo di lavorazione molto specifico e preciso, che necessita di personale altamente qualificato. \n\nIl cuore pulsante della maestria di Pagani è racchiuso in uno dei suoi tratti distintivi ed è ciò che salta subito all’occhio davanti a una Zonda o a una Huayra: il motivo spinato sul cofano anteriore, che simboleggia non solo la ricerca tecnica, ma anche quella estetica…",
        "history_item5$audio": "carbon_it.mp3",
        "history_item6$title": "Gli pneumatici Pirelli",
        "history_item6$abstract": "Dal primo P Zero per la Zonda, al P Zero Corsa per la Huayra fino al P Zero Trofeo R per la Huayra Roadster BC. Senza dimenticare il record al Nürburgring con la Zonda R e il P Zero slick. Un rapporto speciale, quello fra Pirelli e Pagani che ha scritto momenti entrati nella storia dell’automobilismo, momenti fatti di traguardi raggiunti grazie a quell’elemento che identifica le due aziende: la continua innovazione. \n\nPer ogni vettura firmata Pagani Automobili, Pirelli ha creato pneumatici sviluppati ad hoc attingendo anche all’esperienza nel motorsport. E ognuno di questi viene prodotto attraverso il sistema Next MIRS (Modular Integrated Robotized System), una tecnologia completamente robotizzata, proprietaria di Pirelli, per la realizzazione di pneumatici ultra high performance e contraddistinta da un’elevata flessibilità. Elemento che, assieme a personalizzazione e velocità, accomuna il percorso di Pagani Automobili e Pirelli. Un nuovo capitolo della collaborazione fra le due aziende è stato scritto oggi con la presentazione di Pagani Utopia ma la storia continua, sempre seguendo quell’armonia fra potenza e controllo che guiderà anche i prossimi passi assieme.",
        "history_item6$subtitle": "Da dieci anni verso nuovi limiti da esplorare, insieme",
        "history_item6$image": "st-7",
        "history_item6$transcription": "Dal primo P Zero per la Zonda, al P Zero Corsa per la Huayra fino al P Zero Trofeo R per la Huayra Roadster BC. Senza dimenticare il record al Nürburgring con la Zonda R e il P Zero slick. Un rapporto speciale, quello fra Pirelli e Pagani che ha scritto momenti entrati nella storia dell’automobilismo, momenti fatti di traguardi raggiunti grazie a quell’elemento che identifica le due aziende: la continua innovazione. \n\nPer ogni vettura firmata Pagani Automobili, Pirelli ha creato pneumatici sviluppati ad hoc attingendo anche all’esperienza nel motorsport. E ognuno di questi viene prodotto attraverso il sistema Next MIRS (Modular Integrated Robotized System), una tecnologia completamente robotizzata, proprietaria di Pirelli, per la realizzazione di pneumatici ultra high performance e contraddistinta da un’elevata flessibilità. Elemento che, assieme a personalizzazione e velocità, accomuna il percorso di Pagani Automobili e Pirelli. Un nuovo capitolo della collaborazione fra le due aziende è stato scritto oggi con la presentazione di Pagani Utopia ma la storia continua, sempre seguendo quell’armonia fra potenza e controllo che guiderà anche i prossimi passi assieme.",
        "history_item6$audio": "pirelli_it.mp3",
        "history_item7$title": "Il motore",
        "history_item7$abstract": "Mercedes-Benz conta oltre 125 anni di storia. Negli ultimi 50 anni, mentre Mercedes-AMG ha sviluppato i motori dalle migliori prestazioni al mondo, il suo rapporto con Pagani Automobili si è trasformato in una stretta relazione fondata sulla passione comune per l’arte e l’ingegneria automobilistica. \n\nLe due aziende danno vita al cuore pulsante delle Hypercars Pagani in un’impresa ingegneristica modellata sui più severi requisiti di Pagani Automobili. AMG mette a disposizione un ampio team di ingegneri specializzati, cui è affidata la creazione di ogni singolo motore. Un’eccezione nella storia di Daimler: mai un motore era stato progettato per un’altra casa automobilistica. Per ogni ingegnere, un motore. \n\nÈ questo l’impegno per la creazione di un prodotto unico.",
        "history_item7$subtitle": "AMG e Pagani uniti dalla passione per realizzare un prodotto unico",
        "history_item7$image": "st-8",
        "history_item7$transcription": "Mercedes-Benz conta oltre 125 anni di storia. Negli ultimi 50 anni, mentre Mercedes-AMG ha sviluppato i motori dalle migliori prestazioni al mondo, il suo rapporto con Pagani Automobili si è trasformato in una stretta relazione fondata sulla passione comune per l’arte e l’ingegneria automobilistica. \n\nLe due aziende danno vita al cuore pulsante delle Hypercars Pagani in un’impresa ingegneristica modellata sui più severi requisiti di Pagani Automobili. AMG mette a disposizione un ampio team di ingegneri specializzati, cui è affidata la creazione di ogni singolo motore. Un’eccezione nella storia di Daimler: mai un motore era stato progettato per un’altra casa automobilistica. Per ogni ingegnere, un motore. \n\nÈ questo l’impegno per la creazione di un prodotto unico.",
        "history_item7$audio": "engine_it.mp3",
    },
    "en": {
        "history_item0$title": "The beginning of the dream",
        "history_item0$abstract": "This is the story of a dreamer who turned Supercars into Hypercars.It all started in 1955, in Casilda, in the heart of the Argentinian countryside. Horacio Pagani loved playing outdoors and he was so in love with automobiles that he used to spend all his time making miniature car models.He longed to go to Modena, Italy, to design his very own cars. One day while reading Reader’s Digest, Horacio discovered one of the greatest minds of all time, Leonardo Da Vinci who affirmed “Art and Science are two disciplines that must walk together hand in hand”. This maxim opened up a new path for him and from that moment on, Da Vinci’s principles inspired each of Horacio’s creations - and even his studies.",
        "history_item0$image": "st-1",
        "history_item0$subtitle": "How Leonardo Da Vinci’s philosophy of Art and Science completely changed the life of Horacio Pagani.",
        "history_item0$transcription": "This is the story of a dreamer who turned Supercars into Hypercars.It all started in 1955, in Casilda, in the heart of the Argentinian countryside. Horacio Pagani loved playing outdoors and he was so in love with automobiles that he used to spend all his time making miniature car models.He longed to go to Modena, Italy, to design his very own cars. One day while reading Reader’s Digest, Horacio discovered one of the greatest minds of all time, Leonardo Da Vinci who affirmed “Art and Science are two disciplines that must walk together hand in hand”. This maxim opened up a new path for him and from that moment on, Da Vinci’s principles inspired each of Horacio’s creations - and even his studies.",
        "history_item0$audio": "origins_en.mp3",
        "history_item1$title": "The Lamborghini years",
        "history_item1$abstract": "A tent, two bicycles and a suitcase full of dreams. That was all Horacio Pagani and his young wife Cristina had when they decided to set off for Italy in December 1982. Even the letters of introduction and recommendation that Fangio wrote, introducing Horacio to Italy’s major automakers, were not able to guarantee that Horacio obtained employment. Only after several months, the R&D Bodywork  Department of Automobili Lamborghini took him on as a simple worker and soon he was able to show his worth. In 1985, he was put in charge of the new Composite Material department where he took part in the design of the Countach Evoluzione, the car that will show him the way to the future of the sports car.",
        "history_item1$image": "st-2",
        "history_item1$subtitle": "Leaving everything behind to follow a dream. Is what Horacio Pagani did in the craziest but most logical choice of his life.",
        "history_item1$transcription": "A tent, two bicycles and a suitcase full of dreams. That was all Horacio Pagani and his young wife Cristina had when they decided to set off for Italy in December 1982. Even the letters of introduction and recommendation that Fangio wrote, introducing Horacio to Italy’s major automakers, were not able to guarantee that Horacio obtained employment. Only after several months, the R&D Bodywork  Department of Automobili Lamborghini took him on as a simple worker and soon he was able to show his worth. In 1985, he was put in charge of the new Composite Material department where he took part in the design of the Countach Evoluzione, the car that will show him the way to the future of the sports car.",
        "history_item1$audio": "years_en.mp3",
        "history_item2$title": "Modena Design",
        "history_item2$abstract": "Towards the end of the ‘80s, just when the economic crisis seemed to inflict its hardest blow on the global economy, Horacio decided to stake everything on the realization of his dream, at all costs, and he founded Modena Design. During the day for world-class names like Renault, Daihatsu, Ferrari Formula 1, Aprilia and Dallara. But it was overnight that Horacio Pagani worked on his own car, which would soon be dubbed “Fangio F1”, in honor of his great friend, but in March 1999, the car was officially presented in Geneva under the name of Zonda. It was, in every way, a Hypercar. It came from another dimension, fashioned from a dream to generate emotions from the very first instant.",
        "history_item2$subtitle": "When the going gets tough, the tough get going and dreams come true.",
        "history_item2$image": "st-3",
        "history_item2$transcription": "Towards the end of the ‘80s, just when the economic crisis seemed to inflict its hardest blow on the global economy, Horacio decided to stake everything on the realization of his dream, at all costs, and he founded Modena Design. During the day for world-class names like Renault, Daihatsu, Ferrari Formula 1, Aprilia and Dallara. But it was overnight that Horacio Pagani worked on his own car, which would soon be dubbed “Fangio F1”, in honor of his great friend, but in March 1999, the car was officially presented in Geneva under the name of Zonda. It was, in every way, a Hypercar. It came from another dimension, fashioned from a dream to generate emotions from the very first instant.",
        "history_item2$audio": "design_en.mp3",
        "history_item3$title": "Pagani Automobili",
        "history_item3$abstract": "The secret of Pagani Automobili, the workshop “where Hypercars are tailored to their owners’ desires,”is that the dream of that young boy from Argentina has now become the dream of the women and men who commit themselves to furthering that dream every day. Creating bespoke automobiles, unique objects able to convey emotions, is no small thing. Yet there is no Pagani signature vehicle that, over the years, has not been inspired by the finest scientific and design research. The driving force behind Modena Design, at the outset, and then Pagani Automobili is, and has always been, the quest for all that is unique in the creation of automobiles that embody Da Vinci’s concept of Art and Science walking hand in hand.",
        "history_item3$subtitle": "The Pagani “Atelier” where one dream gives birth to hundreds of dreams.",
        "history_item3$image": "st-4",
        "history_item3$transcription": "The secret of Pagani Automobili, the workshop “where Hypercars are tailored to their owners’ desires,”is that the dream of that young boy from Argentina has now become the dream of the women and men who commit themselves to furthering that dream every day. Creating bespoke automobiles, unique objects able to convey emotions, is no small thing. Yet there is no Pagani signature vehicle that, over the years, has not been inspired by the finest scientific and design research. The driving force behind Modena Design, at the outset, and then Pagani Automobili is, and has always been, the quest for all that is unique in the creation of automobiles that embody Da Vinci’s concept of Art and Science walking hand in hand.",
        "history_item3$audio": "cars_en.mp3",
        "history_item4$title": "The Art & Science Research Centre",
        "history_item4$abstract": "In the place where it all began, today we look to the future. The historic headquarters of the former Modena Design has now become the Research Center called “Art and Science”, the philosophy at the heart of Pagani Automobili creations, inspired by the genius of Leonardo da Vinci. The historic venue on Via dell’Artigianato has been transformed into a creative and technological center, able to unite under the same roof the different disciplines involved in the production of exclusive cars, inspired as much by beauty as by scientific research. As in a Renaissance workshop, the Center is a hothouse of ideas, where skills and know-how blend together, contaminating and feeding each other in a continuous exchange of data and information.",
        "history_item4$subtitle": "A place devoted to creativity, created to stimulate the sharing of ideas and skills.",
        "history_item4$image": "st-5",
        "history_item4$transcription": "In the place where it all began, today we look to the future. The historic headquarters of the former Modena Design has now become the Research Center called “Art and Science”, the philosophy at the heart of Pagani Automobili creations, inspired by the genius of Leonardo da Vinci. The historic venue on Via dell’Artigianato has been transformed into a creative and technological center, able to unite under the same roof the different disciplines involved in the production of exclusive cars, inspired as much by beauty as by scientific research. As in a Renaissance workshop, the Center is a hothouse of ideas, where skills and know-how blend together, contaminating and feeding each other in a continuous exchange of data and information.",
        "history_item4$audio": "research_en.mp3",
        "history_item5$title": "Carbon fiber",
        "history_item5$abstract": "Horacio understood the high potential of composite-material-based systems and, in particular, carbon-fiber while making the Countach Evoluzione at Lamborghini. Research in Advanced Composite Materials became a mission for Horacio right from the time the first autoclave was purchased back in the ‘80s. An unstoppable and constantly evolving revolution that turned into an extremely specific, precise processing method requiring a highly skilled workforce. The pulsating heart of Pagani craftsmanship is enshrined within one of its signature features and is the first thing that strikes you when you see a Zonda or a Huayra: the front hood with its herringbone weave pattern, a symbol not only of technical research but also a quest for aesthetics…",
        "history_item5$subtitle": "The herringbone weave pattern a symbol of Pagani craftsmanship made by technical and aestetics research...",
        "history_item5$image": "st-6",
        "history_item5$transcription": "Horacio understood the high potential of composite-material-based systems and, in particular, carbon-fiber while making the Countach Evoluzione at Lamborghini. Research in Advanced Composite Materials became a mission for Horacio right from the time the first autoclave was purchased back in the ‘80s. An unstoppable and constantly evolving revolution that turned into an extremely specific, precise processing method requiring a highly skilled workforce. The pulsating heart of Pagani craftsmanship is enshrined within one of its signature features and is the first thing that strikes you when you see a Zonda or a Huayra: the front hood with its herringbone weave pattern, a symbol not only of technical research but also a quest for aesthetics…",
        "history_item5$audio": "carbon_en.mp3",
        "history_item6$title": "Pirelli tires",
        "history_item6$abstract": "From the first P Zero for the Zonda to the P Zero Corsa for the Huayra and the P Zero Trofeo R for the Huayra Roadster BC. Not to mention the record set at Nürburgring with the Zonda R and the P Zero slick. This special relationship between Pirelli and Pagani has written chapters in the history of the automobile, achieving a series of milestones thanks to a key factor the two companies have in common: ongoing innovation. Pirelli has created tyres developed specifically for every Pagani Automobili car, drawing on its experience in motorsports. And every one of these tyres is produced with Next MIRS (Modular Integrated Robotized System), Pirelli's own fully robot-operated technology for the production of ultra-high-performance tyres that stand out for their great flexibility. Another element shared by Pagani Automobili and Pirelli, along with customisation and speed. A new chapter in the two companies’ partnership was written today with the presentation of the Pagani Utopia, but the story continues, still with a view to that harmony between power and control that will continue to guide the companies’ future steps together.",
        "history_item6$subtitle": "Ten years of exploring new boundaries together",
        "history_item6$image": "st-7",
        "history_item6$transcription": "From the first P Zero for the Zonda to the P Zero Corsa for the Huayra and the P Zero Trofeo R for the Huayra Roadster BC. Not to mention the record set at Nürburgring with the Zonda R and the P Zero slick. This special relationship between Pirelli and Pagani has written chapters in the history of the automobile, achieving a series of milestones thanks to a key factor the two companies have in common: ongoing innovation. Pirelli has created tyres developed specifically for every Pagani Automobili car, drawing on its experience in motorsports. And every one of these tyres is produced with Next MIRS (Modular Integrated Robotized System), Pirelli's own fully robot-operated technology for the production of ultra-high-performance tyres that stand out for their great flexibility. Another element shared by Pagani Automobili and Pirelli, along with customisation and speed. A new chapter in the two companies’ partnership was written today with the presentation of the Pagani Utopia, but the story continues, still with a view to that harmony between power and control that will continue to guide the companies’ future steps together.",
        "history_item6$audio": "pirelli_en.mp3",
        "history_item7$title": "Engine",
        "history_item7$abstract": "Mercedes Benz can look back on over 125 years of history. During the past 50 years, while Mercedes-AMG has developed the world’s best-performing engines, the company’s relationship with Pagani has become a close-knit partnership based on a shared passion for art and automotive engineering. The two enterprises have been creating the pulsating heart of Pagani Hypercars in an engineering feat tailored to Pagani Automobili’s most stringent specifications. An exception in the entire history  of Daimler as never before had an engine ever been designed for another automaker. One man, one engine. Stuttgart’s skilled master mechanics build each block individually. This is the commitment of Mercedes AMG to the creation of a unique product.",
        "history_item7$subtitle": "AMG and Pagani united by passion to create a unique product",
        "history_item7$image": "st-8",
        "history_item7$transcription": "Mercedes Benz can look back on over 125 years of history. During the past 50 years, while Mercedes-AMG has developed the world’s best-performing engines, the company’s relationship with Pagani has become a close-knit partnership based on a shared passion for art and automotive engineering. The two enterprises have been creating the pulsating heart of Pagani Hypercars in an engineering feat tailored to Pagani Automobili’s most stringent specifications. An exception in the entire history  of Daimler as never before had an engine ever been designed for another automaker. One man, one engine. Stuttgart’s skilled master mechanics build each block individually. This is the commitment of Mercedes AMG to the creation of a unique product.",
        "history_item7$audio": "engine_en.mp3",




    }
}