import React from "react";
import { useAtom } from "jotai";

import styles from "./LanguagePage.module.scss";
import { localeAtom } from "../localization/atoms/localeAtom";
import { useStrings } from "../localization/hooks/useStrings";
import { Header } from "../components/Header";
import { LanguageButton } from "../components/LanguageButton";
import { Locale } from "../localization/Localization";
import { useAppNavigate } from "../hooks/useAppNavigate";
import { panelsRoute } from "./RoutePath";

type Props = {
    className?: string
}

export function LanguagePage(props: Props) {
    const [locale, setLocale] = useAtom(localeAtom);
    const strings = useStrings();

    const navigate = useAppNavigate();

    function handleLanguageClick(newLocale: Locale) {
        setLocale(newLocale);

        if (locale)
            navigate(panelsRoute);
    }


    return (
        <>
            <Header alt={true} hasMenuButton={true} />
            <div className={`page ${styles.page} ${props.className ?? ""}`}>
                <div className="page-box">
                    <h1 className={`title ${styles.title}`}>
                        {strings.common$select_language}
                    </h1>
                    <LanguageButton title="ITALIANO" onClick={() => handleLanguageClick("it")} selected={locale === "it"} />
                    <LanguageButton title="ENGLISH" onClick={() => handleLanguageClick("en")} selected={locale === "en"} />
                </div>
            </div>
        </>
    );
}
