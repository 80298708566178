export default {
    "en": {
      "timeline$year1": "1955",
      "timeline$description1": "On November 10, Horacio Pagani is born in Casilda, Argentina.",
      "timeline$country1": "argentina",
      "timeline$year2": "1967",
      "timeline$description2": "At just 12, he builds Mara, a balsa wood model car that foreshadows the style of the future Hypercars he will create.",
      "timeline$country2": "argentina",
      "timeline$year3": "1969",
      "timeline$description3": "A sentence by Leonardo Da Vinci strikes him: «Art and science can walk hand in hand,» profoundly influencing his life and work from that moment on.",
      "timeline$country3": "argentina",
      "timeline$year4": "1971",
      "timeline$description4": "He builds a mini motorbike with his friend Gustavo Marani from spare parts recovered from a dumping ground.",
      "timeline$country4": "argentina",
      "timeline$year5": "1973",
      "timeline$description5": "He turns an old Renault Dauphine into a Buggie, which he loves to drive down the streets of Casilda.",
      "timeline$country5": "argentina",
      "timeline$year6": "1976",
      "timeline$description6": "He builds his \"taller,\" his first atelier-workshop, on a plot of land gifted by his father. He already owns most of the tools from his earlier projects and ingeniously crafts the ones he still needs.",
      "timeline$country6": "argentina",
      "timeline$year7": "1978",
      "timeline$description7": "He designs and manufactures caravans and trailers using fiberglass construction techniques. The potential of composite materials begins to emerge as an increasingly fascinating area of experimentation.",
      "timeline$country7": "argentina",
      "timeline$year8": "1979",
      "timeline$description8": "He designs and builds a single-seater for Formula 2, which becomes part of the official Renault Argentina Team.",
      "timeline$country8": "argentina",
      "timeline$year9": "1982",
      "timeline$description9": "He designs and builds a roughness gauge to measure road deformation, commissioned by the Research and Development Center at the University of Rosario.",
      "timeline$country9": "argentina",
      "timeline$year10": "1983",
      "timeline$description10": "Thanks to the support of Juan Manuel Fangio, he arrives in Italy and begins working at Lamborghini.",
      "timeline$country10": "argentina",
      "timeline$year11": "1984",
      "timeline$description11": "He designs the Countach Evoluzione, the first Lamborghini with a carbon fiber chassis.",
      "timeline$country11": "italy",
      "timeline$year12": "1986",
      "timeline$description12": "He creates drawings and scale models for the Lamborghini P140 project.",
      "timeline$country12": "italy",
      "timeline$year13": "1988",
      "timeline$description13": "He becomes the project manager for the Countach Anniversary. At the end of the year, he leaves Lamborghini to establish the Horacio Pagani Composite Research company.",
      "timeline$country13": "italy",
      "timeline$year14": "1990",
      "timeline$description14": "He collaborates on the design and engineering of the Lamborghini Diablo's bodywork.",
      "timeline$country14": "italy",
      "timeline$year15": "1991",
      "timeline$description15": "He founds Modena Design, a company dedicated to developing technologies focused on advanced composite materials, with its own design center.",
      "timeline$country15": "italy",
      "timeline$year16": "1993",
      "timeline$description16": "He initiates the C8 project, which will lead to the creation of the first Zonda; meets Dieter Zetsche, and begins the collaboration with Mercedes-Benz for the supply of engines.",
      "timeline$country16": "italy",
      "timeline$year17": "1995",
      "timeline$description17": "He designs the body and interiors of the Renault Next, a hybrid concept car made from composite materials. Additionally, he designs and manufactures components for international brands such as Daihatsu and the sulky for trotting races by Lorenzo Baldi.",
      "timeline$country17": "italy",
      "timeline$year18": "1997",
      "timeline$description18": "He designs composite components for the Ferrari Formula 1 car driven by M. Schumacher.",
      "timeline$country18": "italy",
      "timeline$year19": "1998",
      "timeline$description19": "He founds Pagani Automobili to realize his childhood dream of building the most beautiful car in the world.",
      "timeline$country19": "italy",
      "timeline$year20": "1999",
      "timeline$description20": "At the Geneva Motor Show, the Pagani Zonda C12 is unveiled, hailed as a revolution in the automotive world of the time.",
      "timeline$country20": "italy",
      "timeline$year21": "2000",
      "timeline$description21": "A year after the debut of the Zonda C12, Pagani introduces the Zonda S. It is the first road-going Hypercar entirely made of exposed carbon fiber, a true rarity for its time.",
      "timeline$country21": "italy",
      "timeline$year22": "2003",
      "timeline$description22": "At the Geneva Motor Show, the Zonda Roadster is unveiled, featuring innovations and improvements, including a complete redesign of the chassis. This redesign was driven by the need for a convertible cabin and to enhance the car's torsional and bending rigidity.",
      "timeline$country22": "italy",
      "timeline$year23": "2005",
      "timeline$description23": "The Zonda F is created in honor of Juan Manuel Fangio, one of the most famous and beloved Formula 1 drivers in history, and also Horacio Pagani's mentor.",
      "timeline$country23": "italy",
      "timeline$year24": "2006",
      "timeline$description24": "At the Geneva Motor Show, the Zonda Roadster F is unveiled, as lightweight as its Coupé counterpart, thanks to a redesigned chassis and the use of new triaxial carbon fabrics.",
      "timeline$country24": "italy",
      "timeline$year25": "2009",
      "timeline$description25": "The Zonda R and Zonda Cinque are presented at the Geneva Motor Show. The Zonda R is a track-focused Hypercar, the result of exceptional engineering. The Zonda Cinque is created to be the most extreme road-going Zonda ever.",
      "timeline$country25": "italy",
      "timeline$year26": "2010",
      "timeline$description26": "The Zonda Cinque Roadster and Zonda Tricolore make their debut at the Geneva Motor Show. The former is an open-top evolution of the Cinque Coupé, while the latter is created to celebrate the 50th anniversary of the Frecce Tricolori, a tribute to the Italian National Aerobatic Team.",
      "timeline$country26": "italy",
      "timeline$year27": "2011",
      "timeline$description27": "The Pagani Huayra debuts, homologated for road use worldwide. Pagani opens up to the North American market.",
      "timeline$country27": "italy",
      "timeline$year28": "2013",
      "timeline$description28": "The Zonda Revolución makes its debut, emblematic of pure power and the ongoing pursuit of surpassing one's limits. Homologated exclusively for track use, it elevates the 6.0 V12 engine of the \"R\" version to 800 hp and 730 Nm of torque.",
      "timeline$country28": "italy",
      "timeline$year29": "2016",
      "timeline$description29": "The Huayra BC is unveiled in Sicily, an ultra-lightweight vehicle inspired by the Pagani Zonda R and Zonda Cinque, true performance powerhouses. It is a tribute to Benny Caiola, the Italian-American entrepreneur and first buyer of a Pagani.",
      "timeline$country29": "italy",
      "timeline$year30": "2017",
      "timeline$description30": "At the Geneva Motor Show, the Huayra Roadster is unveiled, the first roadster lighter than its coupé counterpart. At Pebble Beach, the Zonda HP Barchetta makes its debut, to celebrate Horacio Pagani's sixtieth birthday. In San Cesario sul Panaro, the Horacio Pagani Museo is established, alongside the inauguration of the new production Atelier.",
      "timeline$country30": "italy",
      "timeline$year31": "2018",
      "timeline$description31": "Horacio Pagani is awarded an Honorary Degree in Vehicle Engineering from the University of Modena and Reggio Emilia.",
      "timeline$country31": "italy",
      "timeline$year32": "2019",
      "timeline$description32": "The Huayra Roadster BC and the one-off Zonda Revo Barchetta are introduced. The former sets the foundation for a new generation of Pagani, while the latter combines the elegance inspired by the Zonda HP Barchetta with the rebellious spirit and extreme performance of the Zonda Revolución. The historic headquarters of Modena Design becomes the Research Center of Pagani Automobili.",
      "timeline$country32": "italy",
      "timeline$year33": "2020",
      "timeline$description33": "The Pagani Imola and Huayra Tricolore make their debut. The Imola is a road-going Hpercar with a pure racing character, while the Huayra Tricolore is an open-top vehicle created to celebrate the 60th anniversary of the 313th Acrobatic Training Group, the Frecce Tricolori.",
      "timeline$country33": "italy",
      "timeline$year34": "2021",
      "timeline$description34": "The Huayra R is unveiled, a track-only car developed without any rule constraints, with one exception: safety.",
      "timeline$country34": "italy",
      "timeline$year35": "2022",
      "timeline$description35": "The Huayra Codalunga is unveiled, a true tribute to Italian coachbuilders and the race cars of the 1960s. With the Huayra Roadster BC project, Horacio Pagani is awarded the 27th ADI Compasso d’Oro. The third chapter in Pagani Automobili's history begins with the debut of the Pagani Utopia. The Arte in Pista program, dedicated to track Hypercars, is inaugurated.",
      "timeline$country35": "italy",
      "timeline$year36": "2023",
      "timeline$description36": "The Huayra Codalunga wins the Design Award at the Concorso d’Eleganza di Villa d’Este. The Imola Roadster is launched, an open-top Hypercar designed to deliver sensational performance both on track and on the road. Modena Design is reborn, now specializing in precision CNC machining.",
      "timeline$country36": "italy",
      "timeline$year37": "2024",
      "timeline$description37": "A year of en plein air Hypercars: the Huayra R Evo and the Utopia Roadster make their debut.",
      "timeline$country37": "italy"
    },
    "it": {
      "timeline$year1": "1955",
      "timeline$description1": "Il 10 novembre nasce Horacio Pagani a Casilda, Argentina.",
      "timeline$country1": "argentina",
      "timeline$year2": "1967",
      "timeline$description2": "A soli 12 anni costruisce Mara, un modellino in legno di balsa che anticipa lo stile delle future Hypercar che realizzerà.",
      "timeline$country2": "argentina",
      "timeline$year3": "1969",
      "timeline$description3": "Una frase di Leonardo Da Vinci lo colpisce: «Arte e Scienza possono camminare mano nella mano», influenzando profondamente la sua vita e il suo lavoro da quel momento in poi.",
      "timeline$country3": "argentina",
      "timeline$year4": "1971",
      "timeline$description4": "Insieme all'amico Gustavo Marani, realizza una mini-moto utilizzando pezzi di ricambio recuperati da una discarica.",
      "timeline$country4": "argentina",
      "timeline$year5": "1973",
      "timeline$description5": "Trasforma una vecchia Renault Dauphine in un Buggie, con il quale ama guidare per le strade di Casilda.",
      "timeline$country5": "argentina",
      "timeline$year6": "1976",
      "timeline$description6": "Costruisce il suo \"taller\", il primo atelier-laboratorio, su un terreno donato dal padre. Possiede già la maggior parte degli attrezzi dai lavori precedenti e s’ingegna per costruire da solo quelli che gli mancano.",
      "timeline$country6": "argentina",
      "timeline$year7": "1978",
      "timeline$description7": "Disegna e produce caravan e roulotte utilizzando tecniche di costruzione in vetroresina. Le potenzialità dei materiali compositi iniziano a diventare un campo di sperimentazione sempre più affascinante.",
      "timeline$country7": "argentina",
      "timeline$year8": "1979",
      "timeline$description8": "Progetta e costruisce una monoposto per Formula 2 che viene inserita nel team ufficiale della Scuderia Renault Argentina.",
      "timeline$country8": "argentina",
      "timeline$year9": "1982",
      "timeline$description9": "Studia e costruisce un misuratore di rugosità da utilizzare per la deformazione stradale, commissionato dal Centro di Ricerca e Sviluppo dell’Università di Rosario.",
      "timeline$country9": "argentina",
      "timeline$year10": "1983",
      "timeline$description10": "Grazie al sostegno di Juan Manuel Fangio, giunge in Italia e inizia a lavorare presso Lamborghini.",
      "timeline$country10": "argentina",
      "timeline$year11": "1984",
      "timeline$description11": "Progetta la Countach Evoluzione, la prima Lamborghini con telaio in fibra di carbonio.",
      "timeline$country11": "italy",
      "timeline$year12": "1986",
      "timeline$description12": "Realizza disegni e modelli in scala per il progetto Lamborghini P140.",
      "timeline$country12": "italy",
      "timeline$year13": "1988",
      "timeline$description13": "Diventa responsabile progetto Countach Anniversary. A fine anno, lascia Lamborghini per fondare la Horacio Pagani Composite Research.",
      "timeline$country13": "italy",
      "timeline$year14": "1990",
      "timeline$description14": "Collabora al design e all’ingegnerizzazione della carrozzeria della Lamborghini Diablo.",
      "timeline$country14": "italy",
      "timeline$year15": "1991",
      "timeline$description15": "Fonda Modena Design, azienda dedicata allo sviluppo di tecnologie incentrate sui materiali compositi avanzati con all’interno il suo centro stile.",
      "timeline$country15": "italy",
      "timeline$year16": "1993",
      "timeline$description16": "Avvia il progetto C8 che porterà alla nascita della prima Zonda; incontra Dieter Zetsche e comincia la collaborazione con Mercedes-Benz per la fornitura dei motori.",
      "timeline$country16": "italy",
      "timeline$year17": "1995",
      "timeline$description17": "Disegna la carrozzeria e gli interni della Renault Next, concept car ibrida realizzata in materiali compositi; inoltre, progetta e realizza componenti per realtà internazionali come Daihatsu e il sulky per le corse al trotto di Lorenzo Baldi.",
      "timeline$country17": "italy",
      "timeline$year18": "1997",
      "timeline$description18": "Progetta componenti in materiali compositi per la Ferrari di Formula 1 guidata da M. Schumacher.",
      "timeline$country18": "italy",
      "timeline$year19": "1998",
      "timeline$description19": "Fonda Pagani Automobili per concretizzare il sogno d'infanzia di costruire l'automobile più bella del mondo.",
      "timeline$country19": "italy",
      "timeline$year20": "1999",
      "timeline$description20": "Al Salone dell’automobile di Ginevra viene presentata la Pagani Zonda C12, accolta come una rivoluzione nel panorama automobilistico dell’epoca.",
      "timeline$country20": "italy",
      "timeline$year21": "2000",
      "timeline$description21": "Un anno dopo il debutto della Zonda C12, Pagani presenta la Zonda S. È la prima Hypercar stradale completamente realizzata in carbonio a vista, una vera rarità per l'epoca.",
      "timeline$country21": "italy",
      "timeline$year22": "2003",
      "timeline$description22": "Al Salone dell’Automobile di Ginevra viene presentata la Zonda Roadster, che porta innovazioni e miglioramenti tra cui una completa riprogettazione del telaio, nata dalla necessità di avere un abitacolo decappottabile e di aumentare la resistenza torsionale e flessionale della vettura.",
      "timeline$country22": "italy",
      "timeline$year23": "2005",
      "timeline$description23": "Nasce la Zonda F in onore di Juan Manuel Fangio, uno dei più celebri e amati piloti di Formula 1 della storia, nonché mentore di Horacio Pagani.",
      "timeline$country23": "italy",
      "timeline$year24": "2006",
      "timeline$description24": "Al Salone dell’Automobile di Ginevra viene presentata la Zonda Roadster F, tanto leggera quanto la controparte Coupé grazie a una riprogettazione del telaio e all'uso di nuovi tessuti triassiali in carbonio.",
      "timeline$country24": "italy",
      "timeline$year25": "2009",
      "timeline$description25": "Vengono presentate la Zonda R e la Zonda Cinque al Salone dell'Automobile di Ginevra. La Zonda R è una Hypercar da pista frutto di un'eccezionale ingegneria. La Zonda Cinque nasce con l'obiettivo di essere la Zonda stradale più estrema di sempre.",
      "timeline$country25": "italy",
      "timeline$year26": "2010",
      "timeline$description26": "Debuttano la Zonda Cinque Roadster e la Zonda Tricolore al Salone dell'Automobile di Ginevra; la prima è un'evoluzione a cielo aperto della Cinque Coupé, mentre la seconda nasce per celebrare il 50° anniversario delle Frecce Tricolori, un omaggio alla Pattuglia Acrobatica Nazionale italiana.",
      "timeline$country26": "italy",
      "timeline$year27": "2011",
      "timeline$description27": "Debutta la Pagani Huayra, omologata per la guida in tutto il mondo. Pagani si apre al mercato del Nord America.",
      "timeline$country27": "italy",
      "timeline$year28": "2013",
      "timeline$description28": "Debutta la Zonda Revolución, emblema della potenza pura e della continua ricerca di superare i propri limiti. Omologata esclusivamente per l'uso in pista, eleva il motore V12 6.0 della versione \"R\" a 800 CV e 730 Nm di coppia.",
      "timeline$country28": "italy",
      "timeline$year29": "2016",
      "timeline$description29": "Viene presentata in Sicilia la Huayra BC, vettura ultraleggera ispirata alla Pagani Zonda R e alla Zonda Cinque, autentici concentrati di prestazioni, in omaggio a Benny Caiola, imprenditore italo-americano e primo acquirente di una Pagani.",
      "timeline$country29": "italy",
      "timeline$year30": "2017",
      "timeline$description30": "Al Salone dell’Automobile di Ginevra viene presentata la Huayra Roadster, prima roadster più leggera della controparte coupé. A Pebble Beach debutta la Zonda HP Barchetta, creata per celebrare i sessant'anni di Horacio Pagani. A San Cesario sul Panaro nasce l’Horacio Pagani Museo e viene inaugurato il nuovo Atelier di produzione.",
      "timeline$country30": "italy",
      "timeline$year31": "2018",
      "timeline$description31": "Horacio Pagani ottiene la Laurea Honoris Causa in Ingegneria del Veicolo presso l’Università di Modena e Reggio Emilia.",
      "timeline$country31": "italy",
      "timeline$year32": "2019",
      "timeline$description32": "Nascono la Huayra Roadster BC e la one-off Zonda Revo Barchetta. La prima getta le basi per una nuova generazione di Pagani, mentre la seconda unisce l'eleganza ispirata alla Zonda HP Barchetta all’anima ribelle e alle prestazioni estreme della Zonda Revolución. La sede storica di Modena Design diventa il Centro di Ricerca di Pagani Automobili.",
      "timeline$country32": "italy",
      "timeline$year33": "2020",
      "timeline$description33": "Debuttano la Pagani Imola e la Huayra Tricolore. L'Imola è una Hypercar stradale dal carattere racing assoluto. La Huayra Tricolore è una vettura scoperta creata per celebrare il 60° anniversario del 313° Gruppo di Addestramento Acrobatico, le Frecce Tricolori.",
      "timeline$country33": "italy",
      "timeline$year34": "2021",
      "timeline$description34": "Viene presentata la Huayra R, un’auto da pista sviluppata senza implicazione di regole, con un’unica eccezione: la sicurezza.",
      "timeline$country34": "italy",
      "timeline$year35": "2022",
      "timeline$description35": "Si svela la Huayra Codalunga, autentico tributo ai carrozzieri italiani e alle auto da corsa degli anni '60. Grazie al progetto Huayra Roadster BC, Horacio Pagani riceve il XXVII Compasso d’Oro ADI. Si apre il terzo capitolo nella storia di Pagani Automobili con il debutto della Pagani Utopia. Viene inaugurato il programma di eventi Arte in Pista, dedicati alle Hypercar da pista.",
      "timeline$country35": "italy",
      "timeline$year36": "2023",
      "timeline$description36": "La Huayra Codalunga vince il Design Award al Concorso d’Eleganza di Villa d’Este. Viene lanciata l'Imola Roadster, Hypercar a cielo aperto progettata per garantire prestazioni sensazionali tanto in pista quanto su strada.\nRinasce Modena Design, che ora si occupa di lavorazioni meccaniche a controllo numerico di precisione.",
      "timeline$country36": "italy",
      "timeline$year37": "2024",
      "timeline$description37": "Un anno di Hypercar en plein air: debuttano la Huayra R Evo e l'Utopia Roaster.",
      "timeline$country37": "italy"
    }
  }