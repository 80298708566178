import React from "react";

import styles from "./Header.module.scss";
import LogoSrc from "../assets/images/layout/logo-pagani-w.png";
import LogoSvg from "../assets/images/layout/pagani_logo.svg";
import BackSrc from "../assets/images/icons/icon-back.svg";
import MenuSrc from "../assets/images/icons/icon-panels.svg";
import { If } from "../mz/types/jsx-control-statements";
import { useNavigationType } from "react-router-dom";
import { useAppNavigate } from "../hooks/useAppNavigate";
import { localeAtom } from "../localization/atoms/localeAtom";
import { useAtomValue } from "jotai";
import { languageRoute, languageStandaloneRoute, panelsRoute } from "../pages/RoutePath";
import { defaultLocale, interfaceLocale } from "../localization/Localization";

type Props = {
    hasMenuButton?: boolean;
    alt?: boolean;
    className?: string;
    style?: React.CSSProperties;
    isStandalone?: boolean;
}

export function Header(props: Props) {
    const navigate = useAppNavigate();
    const navigationType = useNavigationType();
    const locale = useAtomValue(localeAtom);

    const showLocaleMenu = !(window.location.href.includes("lingua"));

    // console.log("nav type", navigationType);

    const canGoBack = navigationType === "PUSH";

    return (
        <div className={`${styles.header} ${props.className ?? ""}`} style={props.style}>
            <div>
                <If condition={canGoBack}>
                    <img
                        className={`${styles.imgButton} ${props.alt ? styles.imgButtonAlt : ""}`}
                        src={BackSrc}
                        alt="back"
                        onClick={() => {
                            if (canGoBack)
                                navigate(-1);
                        }}
                    />
                </If>
                <If condition={props.hasMenuButton! && !canGoBack}>
                    <img
                        className={styles.imgButton}
                        src={MenuSrc}
                        alt="menu"
                        onClick={() => navigate(panelsRoute)}
                    />
                </If>
            </div>
            <img
                className={`${styles.logo}`}
                src={LogoSvg}
                alt="Logo Pagani"
            />
            <div>
                <If condition={showLocaleMenu}>
                    <button className={`${styles.buttonHeader}`} onClick={() => props.isStandalone ? navigate(languageStandaloneRoute) : navigate(languageRoute)}>
                        {locale ? locale?.toUpperCase() : interfaceLocale?.toUpperCase()}
                    </button>
                </If>
            </div>
        </div >
    );
}
