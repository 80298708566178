import React, { Children, useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import imageBack from "../assets/images/icons/icon-back-white.svg";

// Import Swiper styles
import { Autoplay, Pagination, Navigation } from 'swiper';
import 'swiper/css';
import { If } from '../mz/types/jsx-control-statements';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

type Props = {
    children: React.ReactNode;
    slidesPerView?: number | 'auto';
    pagination?: boolean;
}

export default function Carousel(props: Props) {
    const [prevEl, setPrevEl] = useState<HTMLElement | null>(null)
    const [nextEl, setNextEl] = useState<HTMLElement | null>(null)
    return (
        <>
            <If condition={props.pagination ?? false}>
                <div style={{ marginBottom: "7rem" }}>
                    <Swiper
                        pagination={{
                            type: "fraction",
                        }}
                        modules={[Autoplay, Pagination, Navigation]}
                        spaceBetween={10}
                        slidesPerView={props.slidesPerView ?? 'auto'}
                        autoplay={true}
                        loop={true}
                        navigation={{ prevEl, nextEl }}
                        autoHeight={true}
                    >
                        {props.children}

                        <div className='carousel-navigation-wrapper'>
                            <div className="swiper-button-prev" ref={(node) => setPrevEl(node)}>
                                <img src={imageBack} />
                            </div>
                            <div className="swiper-button-next" ref={(node) => setNextEl(node)}>
                                <img src={imageBack} />
                            </div>
                        </div>
                    </Swiper>
                </div>

            </If>

            <If condition={!props.pagination}>
                <Swiper
                    className='swiper-overflow'
                    wrapperClass="swiper-wrapper 2"
                    injectStyles={["width:100%; background-color: red"]}
                    modules={[Autoplay]}
                    spaceBetween={10}
                    slidesPerView={props.slidesPerView ?? 'auto'}
                    autoplay={true}
                    loop={true}
                >
                    {props.children}
                </Swiper>
            </If>
        </>

    )
}
