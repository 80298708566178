export const languageRoute = "/lingua";
export const eventRoute = "/evento";
export const panelsRoute = "/navigazione";
export const hypercarRoute = "/hypercar";
export const hypercarDetailRoute = "/hypercar/dettagli";
export const historyRoute = "/storia";
export const histotyDetailRoute = "/storia/dettagli";
export const privacyRoute = "/privacy-policy";
export const cookieRoute = "/cookie-policy";
export const timelineRoute = "/timeline-app";
export const timelineStandaloneRoute = "/timeline";
export const languageStandaloneRoute = "/lingua-app";
export const brandRoute = "/partner";
export const brandDetailRoute = "/partner/dettagli";
export const errorRoute = "/errore";


export type RoutePath = "/"
    | typeof languageRoute
    | typeof eventRoute
    | typeof hypercarRoute
    | typeof hypercarDetailRoute
    | typeof historyRoute
    | typeof histotyDetailRoute
    | typeof panelsRoute
    | typeof privacyRoute
    | typeof timelineRoute
    | typeof timelineStandaloneRoute
    | typeof languageStandaloneRoute
    | typeof brandRoute
    | typeof brandDetailRoute
    | typeof cookieRoute
    | typeof errorRoute
    ;